<template>
	<article class="col1">
		<div 
			id="Purpose" 
			class="Item colblock">
			<p>This Living Style Guide is the common language for our development,
			marketing, and product innovation teams within Studyportals.</p>

			<ul>
				<li>It helps us provide a consistent and coherent user experience which is crafted by different teams.</li>
				<li>It speeds up the development and design process by making us focus on solving real user problems.</li>
				<li>It increases our confidence in design decisions and allows a baseline for critique.</li>
			</ul>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'Purpose'
	}
</script>

<style lang="scss">
</style>
<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedDesignDirection/>
	</div>
</template>

<script>
	import SharedDesignDirection from '../components/Shared/Components/DesignDirection.vue';
	import store from '../store';

	export default {
		name: 'DesignDirection',
		components: { SharedDesignDirection },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
import { readonly } from "vue";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
const store = {
    state: readonly(state),
    mutations,
    getters
};
export default store;

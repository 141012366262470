<template>
	<article 
		id="AboutStudyportals" 
		class="Item col1">
		<section class="SubItem colblock">
			<h2>Who is Studyportals?</h2>
			<p>
				Studyportals is the global education platform that enables students
				to find and compare study opportunities across borders.
				Our goal is having all internationally-oriented education options
				and related information, searchable and comparable in one place
				to empower students to make a better choice.
			</p>
		</section>

		<section class="SubItem colblock">
			<h2>"Empowering the World to Choose Education"</h2>
			<p>
				We are on a mission to making higher education choice transparent.
				Every day, we’re inspired by students worldwide who will go on to
				become the next generation of world leaders and innovators.
				We empower students to not only develop their personal and
				professional lives, but also to shape the lives of those around them.
			</p>
		</section>

		<section class="SubItem colblock">
			<h2>Our Brand Manifesto</h2>
			<p>We choose to:</p>
			<ul>
				<li>Place students’ interests first</li>
				<li>Empower any student from any background to study anywhere</li>
				<li>Present the best info about study opportunities worldwide, so students can make better choices</li>
				<li>Support universities in improving and diversifying their classrooms</li>
				<li>Educate ourselves and others by sharing real data, insights, and experiences</li>
				<li>Take a long-term view and provide a great platform without compromise</li>
				<li>Focus on ideas that are truly meaningful and important</li>
				<li>Tell the world: never stop learning</li>
			</ul>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Studyportals',
		data () {
			return {
				msg: 'hello'
			}
		}
	}
</script>
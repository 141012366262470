<template>
	<div id="galactus">
		<SharedModal/>
	</div>
</template>

<script>
	import SharedModal from '../components/Shared/Components/Modal.vue';
	import store from '../store';

	export default {
		name: 'ComponentsModal',
		components: { SharedModal },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
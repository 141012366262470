<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedWriting/>
	</div>
</template>

<script>
	import SharedWriting from '../components/Shared/Components/Writing.vue';
	import store from '../store';

	export default {
		name: 'ContentWriting',
		components: { SharedWriting },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
<template>
	<span class="Label">
		{{ labelText }}
	</span>
</template>

<script>
	export default {
		name: 'StyleGuideLabel',
		props: {
			labelText: {
				type: String,
				default: ''
			}
		},
		data() {
			return {}
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	.Label{
		background-color: $GreyLLL;
		color: $GreyDD;
		font-size: 0.8rem;
		padding: 0.3rem 1rem;
	}
</style>
<template>
	<article 
		id="GridAndLayout" 
		class="col1">
		<section 
			v-if="getTarget === 'student'" 
			class="colblock SubItem">
			<h2>Breakpoints</h2>
			<p>
				Taking consistency, scalablity and a mobile first approach into consideration,
				we defined the following breakpoints for our websites. With these breakpoints
				we don’t try to target certain device models, but try to target groups of
				devices based on their screen sizes.
			</p>
			<p>
				You can find the top 10 mobile and desktop screen resolutions in 
				<a 
					href="https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/top10+mobile+and+desktop+screen+resolutions.pdf"
					title="The top 10 mobile and desktop screen resolutions"
					target="_blank">
				this visualisation</a>. If you are interested in getting more detailed insights 
				into screen resolutions, use 
				<a 
					href="https://docs.google.com/spreadsheets/d/1lrcGRFt9g3O05tbnRPpaxpiLqej6SPE7BN7bEfNuNEc/edit#gid=40777492"
					title="Screen resolutions tool Q1 2019">
				this tool</a>. Both the visualisation and the tool use data from Q1 2019.
			</p>
			<figure class="VisualBlock">
				<img 
					src="http://monet-prtl-co.imgix.net/StyleGuide/Lay-out/Grid/Breakpoints.svg"
					alt="Breakpoints visualized with pixel values included">
				<figcaption class ="ImageCaption">Screensize and device spectrum</figcaption>
			</figure>
		</section>

		<section 
			v-if="getTarget === 'student'" 
			class="colblock SubItem">
			<h2>Grid</h2>
			<p>
				All of our websites make use of our custom build fluid responsive 12-colum
				grid (fully built in Sass). Column width is set to <strong>5 rem</strong>
				(equals to 80px for extra large breakpoint). Between every column a
				horizontal gutter is set to <strong>1 rem</strong> (equals to 16px for
				extra large	breakpoint). Column width is automatically calculated in
				percentages based on the given proportions.
			</p>
			<figure class="VisualBlock">
				<img 
					src="http://monet-prtl-co.imgix.net/StyleGuide/Lay-out/Grid/Grid.svg"
					alt="Columns visualized with width and spacing included">
				<figcaption class ="ImageCaption">12-column responsive grid</figcaption>
			</figure>
		</section>

		<section 
			v-if="getTarget === 'student'" 
			class="colblock SubItem">
			<h2>Layout</h2>
			<p>
				Currently we have 1-coloum (&#9312;), 2-column (&#9313; & &#9314;), and
				mixed-column (&#9312;+&#9314;) options. The graph below demonstrates layout
				behaviours at different breakpoints. To make our layout less cluttered
				and more user-friendly on mobile devices, by default there's a 1rem top
				and bottom padding on every row. The same is done for all the row child
				elements (.colblock) with a top and bottom margin.
			</p>
			<figure class="VisualBlock">
				<img 
					src="http://monet-prtl-co.imgix.net/StyleGuide/Lay-out/Grid/GridLayout.svg"
					alt="Layout options visualized for different breakpoints">
				<figcaption class ="ImageCaption">Layout options and behaviours</figcaption>
			</figure>
		</section>

		<!-- CLIENT -->
		<section 
			v-if="getTarget === 'client'" 
			class="colblock SubItem">
			<h2>Breakpoints</h2>
			<p>
				Our client-facing products are featured with content-heavy interfaces
				which our users use mainly for entity management and reports viewing.
				At the moment we are only targeting bigger screens (Extralarge &amp; Wrapper).
			</p>
			<figure class="VisualBlock">
				<img 
					src="http://monet-prtl-co.imgix.net/StyleGuide/Lay-out/Grid/DevicesClients.svg"
					alt="Breakpoints visualized with pixel values included">
				<figcaption class ="ImageCaption">Screensize and device spectrum</figcaption>
			</figure>
		</section>

		<section 
			v-if="getTarget === 'client'" 
			class="colblock SubItem">
			<h2>Grid</h2>
			<p>
				All of our websites make use of our custom build fluid responsive 12-colum
				grid (fully built in Sass). Column width is set to <strong>5 rem</strong>
				(equals to 80px for extra large breakpoint). Between every column a
				horizontal gutter is set to <strong>1.25 rem</strong> (equals to 20px for
				extra large	breakpoint). Column width is automatically calculated in
				percentages based on the given proportions.
			</p>
			<figure class="VisualBlock">
				<img 
					src="http://monet-prtl-co.imgix.net/StyleGuide/Lay-out/Grid/Grid.svg"
					alt="Columns visualized with width and spacing included">
				<figcaption class ="ImageCaption">12-column responsive grid</figcaption>
			</figure>
		</section>

		<section 
			v-if="getTarget === 'client'" 
			class="colblock SubItem">
			<h2>Layout</h2>
			<p>
				Currently we have 1-column (&#9312;), 2-column (&#9313; &amp; &#9314;), and
				mixed-column (&#9312;+&#9314;).	By default there's a <strong>1rem</strong> top and bottom
				padding on every row. The same is done for all the row child elements
				(.colblock) with a top and bottom margin.
			</p>
			<figure class="VisualBlock">
				<img 
					src="http://monet-prtl-co.imgix.net/StyleGuide/Lay-out/Grid/LayoutClients.svg"
					alt="Layout options visualized for different breakpoints">
				<figcaption class ="ImageCaption">Layout options and behaviours</figcaption>
			</figure>
		</section>



	</article>
</template>

<script>
	import store from '../../../store';
	export default {
		name: 'GridAndLayout',
		data () {
			return {
				msg: ''
			}
		},
		computed: {
			getTarget() {

				return store.getters.target();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.ContentWrapper {
		.SubItem {
			.VisualBlock {
				flex-flow: nowrap column;
			}
		}
	}
</style>

<template>
	<article 
		id="CoverPhotos" 
		class="Item col1">
		<Introduction/>
		<SizeResizing/>
		<Overlays/>
		<NoPhotoAvailable/>
	</article>
</template>

<script>
    import Introduction from './Introduction.vue';
    import SizeResizing from './SizeResizing.vue';
    import Overlays from './Overlays.vue';
    import NoPhotoAvailable from './NoPhotoAvailable.vue';
    export default {
        name: 'CoverPhotos',
        components: {
            NoPhotoAvailable,
            Overlays,
            SizeResizing,
            Introduction
        },
    }
</script>

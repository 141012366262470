<template>
	<article
		id="Modal"
		class="Item col1">
		<section
			class="SubItem colblock">
			<h2>Introduction</h2>
			<p>
				Modal dialogs can be triggered by user actions or system events. 
				When triggered,  it opens a window on top of an overlay, informing 
				users about certain information or forcing users to  provide certain input. 
				Due to it’s highly-disruptive appearance, it can be used to attract users’ 
				attention and require important actions.
			</p>
			<div class="PackageLinks">
				<p>
					Access your component via:
				</p>
				<PackageLink
					:link_type="'npm'"
					:package_name="package_name"
				/>
				<PackageLink
					:link_type="'github'"
					:package_name="package_name"
				/>
			</div>
		</section>
		<section
			class="SubItem colblock">
			<h2>Types of modal dialogs</h2>
			<p>
				We defined 2 types of modal-dialogs: regular modal dialog and complex modal dialog. 
				While they share a lot characteristics in common, it’s good to consider the following 
				for choosing the appropriate type of modal dialog:
			</p>
			<ul>
				<li>Purpose (Why do we use it?)</li>
				<li>Content (What information should be displayed there?)</li>
				<li>Context (What scenario are we looking at?)</li>
			</ul>
			<figure class="VisualBlock">
				<img
					alt="Regular and complex modal dialogs"
					src="//monet-prtl-co.imgix.net/StyleGuide/Modal/modal_types.svg">
				<figcaption class="ImageCaption">Regular and complex modal dialogs</figcaption>
			</figure>
		</section>
		<section
			class="SubItem colblock">
			<h2>Regular modal dialogs</h2>
			<p>
				Regular modal dialog can be used to inform users about simple yet important tasks. 
				It has a fixed width on both mobile device and desktop, and a fixed height on desktop. 
				Height on mobile device however can be flexible within a specific range, depending on the content.
			</p>
			<p>
				If content is longer than the fixed height, content becomes scrollable with a sticky CTA 
				(if applicable). If content is too long or interaction gets too complex, consider switching 
				to the complex modal dialog (see the section below).
			</p>
			<figure class="VisualBlock">
				<img
					alt="Regular modal dialog on desktop"
					src="//monet-prtl-co.imgix.net/StyleGuide/Modal/simple_modal_desktop.svg">
				<figcaption class="ImageCaption">Regular modal dialog on desktop</figcaption>
			</figure>
			<figure class="VisualBlock">
				<img
					alt="Height variations of regular modal dialogs on mobile device"
					src="//monet-prtl-co.imgix.net/StyleGuide/Modal/simple_modal_mobile.png">
				<figcaption class="ImageCaption">Height variations of regular modal dialogs on mobile device</figcaption>
			</figure>
			<example-wrapper
				:type="'example'"
				labelText="Example">

				<div class="Wrapper Horizontal">
					<img
						alt="Layout and component spacing"
						src="//monet-prtl-co.imgix.net/StyleGuide/Modal/simple_modal_example.png">
				</div>

			</example-wrapper>
			<div class="VisualBlock">
				<div class="ModalAnimations">
					<img
						alt="Opening &amp; closing modal dialogs on desktop and mobile device"
						src="https://studyportals-styleguide.s3.eu-central-1.amazonaws.com/Modal/Modal-desktop.gif">
					<img
						alt="Opening &amp; closing modal dialogs on desktop and mobile device"
						src="https://studyportals-styleguide.s3.eu-central-1.amazonaws.com/Modal/Modal-mobile.gif">
				</div>
				<span class="ImageCaption">Opening &amp; closing modal dialogs on desktop and mobile device</span>
			</div>
		</section>
		<section class="SubItem colblock">
			<p>Code snippet for regular modal dialog:</p>
			<CodeSnippets 
				:package_name="package_name"
				:identifiers="['simplemodal', 'modalcss']"
			/>
		</section>
		<section
			class="SubItem colblock">
			<h2>Complex modal dialogs</h2>
			<p>
				Complex modal dialog is larger in size and it is designed to host more complex contents and interactions. 
				It has a fixed width and height on desktop, however on mobile device it becomes full screen, providing 
				an easier browsing experience for users.
			</p>
			<p>
				If content is longer than the fixed height, content becomes scrollable.
			</p>
			<figure class="VisualBlock">
				<img
					alt="Complex modal dialog on desktop"
					src="//monet-prtl-co.imgix.net/StyleGuide/Modal/complex_modal_desktop.svg">
				<figcaption class="ImageCaption">Complex modal dialog on desktop</figcaption>
			</figure>
			<div class="MobileExamples">
				<figure class="VisualBlock">
					<img
						alt="Complex modal dialog on mobile device"
						src="//monet-prtl-co.imgix.net/StyleGuide/Modal/complex_modal_mobile.svg">
					<figcaption class="ImageCaption">Complex modal dialog on mobile device</figcaption>
				</figure>
				<figure class="VisualBlock">
					<img
						alt="Opening &amp; closing modal dialogs on desktop and mobile device"
						src="https://studyportals-styleguide.s3.eu-central-1.amazonaws.com/Modal/Modal-mobile-fullscreen.gif">
					<figcaption class="ImageCaption">Opening &amp; closing modal dialogs on desktop and mobile device</figcaption>
				</figure>
			</div>
			<example-wrapper
				:type="'example'"
				labelText="Example">

				<div class="Wrapper Horizontal">
					<img
						alt="Layout and component spacing"
						src="//monet-prtl-co.imgix.net/StyleGuide/Modal/complex_modal_example.png">
				</div>

			</example-wrapper>
		</section>
		<section class="SubItem colblock">
			<p>Code snippet for complex modal dialog:</p>
			<CodeSnippets 
				:package_name="package_name"
				:identifiers="['complexmodal', 'modalcss']"
			/>
		</section>
		<section
			class="SubItem Motion colblock">
			<h2>Motion</h2>
			<h3>Movement</h3>
			<p>
				On mobile, the modal window appears from the bottom of the viewport, as the overlay fades in.
			</p>
			<p>
				On desktop modals, the window not only fades in, but also moves 16 pixels from the top. 
				The outro animation reverts this translation. The overlay also fades in.
			</p>
			<h3>Timing</h3>
			<p>
				Both desktop and mobile share the same timing for the motion:<br>
				<br>
				Intro:<br>
				Overlay duration: <strong>0.5s</strong><br>
				Modal duration: <strong>0.4s</strong> (triggered <strong>0.1s</strong> after start)<br>
				Easing bezier: <strong>0.25, 0, 0.25, 1</strong><br>
				<br>
				Outro:<br>
				Duration: <strong>0.2s</strong><br>
				Easing bezier: <strong>0.5, 0, 1, 1</strong> (overlay and modal triggered at the same time)
			</p>
		</section>
		<section
			class="SubItem colblock">
			<h2>Dismissing modal dialogs</h2>
			<p>
				Modal dialogs can be dismissed by clicking the “cross” symbol on the up 
				right corner or by clicking outside of the window.
			</p>
		</section>
	</article>
</template>

<script>
	import ExampleWrapper from './ExampleWrapper.vue';
	import PackageLink from '../Patterns/PackageLink/PackageLink';
	import CodeSnippets from '../Patterns/CodeSnippets/CodeSnippets';
	import store from '../../../store'

	export default{
		name: 'Modal',
		components:{
			ExampleWrapper,
			PackageLink,
			CodeSnippets
		},
		data() {
			return {
				package_name: 'Modal'
			}
		},
		computed:{
			getTarget(){

				return store.getters.target();
			}
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	#Modal{
		.VisualBlock{
			margin: 3rem 0 0;

			img {
				max-width: 100%;
			}

			figcaption {
				max-width: 100%;
			}
		}
		.ModalAnimations {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-column-gap: 3rem;
			margin-bottom: 1rem;
		}
		.MobileExamples {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 3rem;

			.VisualBlock {
				img {
					margin: 0 auto;
				}
			}
		}
		.PackageLinks {
			margin-top: 2rem;

			.PackageLinkWrapper {
				margin-right: 1rem;
			}
		}
		.ExampleIntro{
			width: 100%;
			text-align: center;
			margin: 1rem;
		}
		.Motion {
			h3 {
				margin: 3rem 0;
			}

			p:first-of-type {
				margin-top: 0;
			}
		}
	}
</style>

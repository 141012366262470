<template>
	<article class="Item col1">
		<section class="SubItem colblock">
			<h2>Introduction</h2>

			<p>
				While our design principles serve as a manifesto for what we value as a team,
				our directions guide us to translate design requirements into visual elements.
			</p>

			<img src="http://monet-prtl-co.imgix.net/StyleGuide/DesignDirection/Visualisation.png" >
		</section>
	</article>
</template>

<script>
	import store from '../../../store';
	export default {
		name: 'DesignDirection',
		computed:{
			getTarget(){

				return store.getters.target();
			}
		}
	}
</script>

<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedGrid/>
	</div>
</template>

<script>
	import SharedGrid from '../components/Shared/Components/GridAndLayout.vue';
	import store from '../store';

	export default {
		name: 'LayoutGrid',
		components: { SharedGrid },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
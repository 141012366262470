<template>
	<section class="SubItem colblock">
		<h2>Size and Resizing</h2>
		<p>
			Cover photos take the full width of the pages. They are 480px high for the L and XL breakpoints, 200px for S
			and M breakpoints.
		</p>
	</section>
</template>

<script>
    export default {
        name: "SizeResizing"
    }
</script>

/**
 * @file Tooltip.js (___CUSTOM ES6 COMPATIBLE VERSION___)
 *
 * This script will add functionality for the ToolTips on the page.
 *
 * The tooltip should appear when the information icon is clicked / touched.
 *
 * The HTML mark-up for the ToolTips should look like this:
 * <span class="InfoIcon" data-tooltip-text="Your tooltip text"></span>
 */

class Tooltip {

	constructor(container){

		console.log(container)
		this.container = document.getElementById(container);
		console.log(this.container)
		if(!this.container){

			console.error("Tooltip.init: no valid container element provided");
			return;
		}

		this.init();
	}
	/**
	 * Initializes the main functionality.
	 *
	 * @return void
	 */

	init(){

		this.container.addEventListener('click', this._onDocumentClick.bind(this));
		this._bindIconEvents();

		// On page scroll, resize or orientationchange recalculate the position of the tooltip.
		window.addEventListener('orientationchange', () => {

			requestAnimationFrame(this._getTooltipElements.bind(this));
		});

		window.addEventListener('resize', () => {

			requestAnimationFrame(this._getTooltipElements.bind(this));
		});

		window.addEventListener('scroll', () => {

			requestAnimationFrame(this._getTooltipElements.bind(this));
		});
	}

	/**
	 * Show/hide the tooltip on a click on the whole document
	 * @param event
	 * @private
	 */

	_onDocumentClick(event){

		// First hide all currently visible tooltips
		const infoIcons = this.container.getElementsByClassName('InfoIcon');
		Array.from(infoIcons).forEach((icon) => {

			this.hideTooltip(icon);
		});

		if(!event.target.classList.contains('InfoIcon')){

			return;
		}

		// If the user clicks on a tooltip, show its content if necessary.
		const tooltipIcon = this._validateCreatable(event.target);
		if(tooltipIcon === null){

			console.warn('Tooltip: not able to create a tooltip element.');
			return;
		}

		this.showTooltip(tooltipIcon, false);
	}

	/**
	 * Add click events to all the currently present tooltips
	 * @private
	 */

	_bindIconEvents(){

		Array.from(this.container.getElementsByClassName('InfoIcon')).forEach((element) => {

			element.addEventListener('mouseenter', this._onMouseEnter.bind(this));
			element.addEventListener('mouseleave', this._onMouseLeave.bind(this));
		});
	}

	/**
	 * On exiting the tooltip icon, hide the tooltip
	 * @param event
	 * @private
	 */

	_onMouseLeave(event){

		let infoIcon = event.target;
		if(event.target.closest('.InfoIcon')){

			infoIcon = event.target.closest('.InfoIcon');
		}

		if(!infoIcon.classList.contains('InfoIcon')){

			return;
		}

		this.hideTooltip(infoIcon);
	}

	/**
	 * On entering the tooltip icon with the mouse, show the tooltip.
	 *
	 * @param event
	 * @private
	 */

	_onMouseEnter(event){

		if(!event.target.classList.contains('InfoIcon')){

			return;
		}

		const tooltipIcon = this._validateCreatable(event.target);
		if(tooltipIcon === null){

			console.warn('Tooltip: not able to create a tooltip element.');
			return;
		}

		this.showTooltip(tooltipIcon, false);
	}

	/**
	 * Get the active tooltip element (icon/trigger, tooltip).
	 *
	 * @private
	 * @return void
	 */

	_getTooltipElements(){

		/*
			Checks if the current active tooltip element which was triggered
			by an event is actually set.
		*/

		const $activeElements = this.container.querySelectorAll('.InfoToolTip.is-Active');
		Array.from($activeElements).forEach(($tooltip) => {

			// Get the parent element so we can position the tooltip.
			const $tooltipIcon = $tooltip.closest('.InfoIcon') || $tooltip.parentNode;
			if(!$tooltipIcon){

				return;
			}

			// If a tooltip is generated (not triggered by hover or click) don't hide the tooltip on scroll.
			if(!$tooltip.classList.contains('js-GeneratedByDefault')){

				this.hideTooltip($tooltip);
				return;
			}

			this.setPositionToolTip($tooltipIcon, $tooltip);
		});
	}

	/**
	 * Checks if an tooltip should be created.
	 *
	 * @param {Element} tooltipIcon
	 * @private
	 * @returns {*}
	 */

	_validateCreatable(tooltipIcon) {

		// Check for touch if target element has parent icon
		if(!tooltipIcon.classList.contains('InfoIcon')){

			tooltipIcon = tooltipIcon.closest('.InfoIcon');
		}

		let toolTip = tooltipIcon.querySelector('.InfoToolTip');

		// If tooltip exists and is already active then do nothing
		if(toolTip !== null && toolTip.classList.contains('is-Active')){

			return null;
		}

		// If no text is present in the tooltip then do nothing
		if(tooltipIcon.hasAttribute('data-tooltip-text') === false || tooltipIcon.getAttribute('data-tooltip-text') === ''){

			return null;
		}

		return tooltipIcon;
	}

	/**
	 * Create a tooltip and, inject it into the page
	 * and set the text which gets retrieved from the data-tooltip-text attribute.
	 *
	 * @param {Element} $tooltipIcon
	 * @param {Boolean} [$generatedByDefault] - if undefined it validates to true.
	 * @return void
	 */

	showTooltip($tooltipIcon, $generatedByDefault){

		// If not defined then we know that the tooltip is 'gena'
		if($generatedByDefault === undefined){

			$generatedByDefault = true;
		}

		let $toolTip = $tooltipIcon.querySelector('.InfoToolTip');
		if(!$toolTip){

			const $infoIconText = $tooltipIcon.getAttribute('data-tooltip-text');
			const $toolTipContent = document.createElement('span');
			$toolTip = document.createElement('span');

			$toolTipContent.classList.add('InfoToolTipContent');
			$toolTip.classList.add('InfoToolTip');
			$toolTipContent.innerHTML = $infoIconText;
			$toolTip.insertBefore($toolTipContent, null);
			$tooltipIcon.insertBefore($toolTip, null);
		}

		// Add to the tooltip all the needed styling.
		$toolTip.classList.add('is-Active');

		// If the tooltip is generated (not triggered by hover or click) add an extra class.
		if($generatedByDefault === true){

			$toolTip.classList.add('js-GeneratedByDefault');
		}

		// Put the tooltip in the right position.
		this.setPositionToolTip($tooltipIcon, $toolTip);
	}

	/**
	 * Position the tooltip box in relation to the element with class 'InfoIcon'.
	 *
	 * @param {Element} tooltipIcon
	 * @param {Element} tooltip
	 *
	 * @return void
	 */

	setPositionToolTip(tooltipIcon, tooltip){

		const properties = this.getTooltipProperties(tooltipIcon, tooltip);

		// Initial tooltip positioning.
		tooltip.style = '';
		tooltip.style.left = `${properties.infoIconPosition.left + properties.iconWidth / 2}px`;
		tooltip.style.top = `${properties.infoIconPosition.top + properties.iconHeight + 16}px`;

		// No need to adjust the tooltip positioning.
		if(this.inViewport(tooltip)){

			return;
		}

		/*
			When the viewport is smaller than the tooltip width, align the box to the left,
			and remove the pointing arrow (nose).
		 */

		if(properties.windowWidth <= properties.boxWidth){

			this.alignToLeftOfIconNoNose(tooltip);
		}

		/*
			When the tooltip gets out of the viewport on the right side, align it to
			the left side of the icon.
		 */

		else if(tooltip.getBoundingClientRect().right >= properties.windowWidth){

			this.alignToLeftOfIcon(tooltip, properties);
		}

		/*
			When the tooltip gets out of the viewport on the left side, align it to the
			right side of the icon.
		 */

		else if(tooltip.getBoundingClientRect().left <= 0){

			this.alignToRightOfIcon(tooltip, properties);
		}

		// In case a tooltip is aligned to close to the bottom of the viewport align it on top of the icon.
		else if(tooltip.getBoundingClientRect().bottom >= properties.windowHeight){

			this.alignToTopOfIcon(tooltip, properties);
		}

		else{

			// No action.
		}
	}

	/**
	 * Get all the properties needed for the positioning of the tooltip.
	 *
	 * @param tooltipIcon
	 * @param tooltip
	 * @return {{iconHeight: number, iconWidth: number, windowHeight: number,
	 * boxWidth: number, windowWidth: number, infoIconPosition: ClientRect | DOMRect}}
	 */

	getTooltipProperties(tooltipIcon, tooltip){

		return {
			iconWidth: tooltipIcon.getBoundingClientRect().width,
			iconHeight: tooltipIcon.getBoundingClientRect().height,
			boxWidth: tooltip.getBoundingClientRect().x,
			windowWidth: document.body.getBoundingClientRect().width,
			windowHeight: window.innerHeight,
			infoIconPosition: tooltipIcon.getBoundingClientRect()
		}
	}

	/**
	 * When the viewport is smaller than the tooltip width, align the box to the left,
	 * and remove the pointing arrow (nose).
	 *
	 * @param tooltip
	 * @return void
	 */

	alignToLeftOfIconNoNose(tooltip){

		tooltip.style.right = 'auto';
		tooltip.style.left = '0';
		tooltip.classList.add('NoNose');
	}

	/**
	 * When the tooltip gets out of the viewport on the right side, align it to
	 * the left side of the icon.
	 *
	 * @param tooltip
	 * @param properties
	 * @return void
	 */

	alignToLeftOfIcon(tooltip, properties){

		if(properties.infoIconPosition.left + properties.iconWidth < properties.boxWidth){

			tooltip.style.right = '0';
			tooltip.style.left = 'auto';
			tooltip.classList.add('NoNose');
		}
		else{

			tooltip.style.left = 'auto';
			tooltip.style.right = properties.windowWidth - properties.infoIconPosition.left - properties.iconWidth + 'px';
			tooltip.classList.add('NoseRight');
		}
	}

	/**
	 * When the tooltip gets out of the viewport on the left side, align it to the
	 * right side of the icon.
	 *
	 * @param tooltip
	 * @param properties
	 * @return void
	 */

	alignToRightOfIcon(tooltip, properties){

		if((properties.windowWidth - properties.infoIconPosition.left) < properties.boxWidth){

			tooltip.style.right = 'auto';
			tooltip.style.left = 0;
			tooltip.classList.add('NoNose');
		}
		else{

			tooltip.style.right = 'auto';
			tooltip.style.left = properties.infoIconPosition.left + 'px';
			tooltip.classList.add('NoseLeft');
		}
	}

	/**
	 * In case a tooltip is aligned to close to the bottom of the viewport align
	 * it on top of the icon.
	 *
	 * @param tooltip
	 * @param properties
	 * @return void
	 */

	alignToTopOfIcon(tooltip, properties){

		tooltip.style.top = null;
		tooltip.style.bottom = `${(properties.windowHeight - properties.infoIconPosition.top) + 16}px`;
		tooltip.classList.add('NoseBottom');
	}

	/**
	 * Check if the tooltip information box is fully in the viewport.
	 *
	 * @param {Element} element
	 * @returns {boolean}
	 */

	inViewport(element){

		const elementBounds = element.getBoundingClientRect();
		const scrollPosition = document.querySelector('html').scrollTop;

		return elementBounds.top + scrollPosition >= 0 &&
			elementBounds.left >= 0 &&
			elementBounds.bottom + scrollPosition <= window.innerHeight &&
			elementBounds.right <= document.body.getBoundingClientRect().width;
	}

	/**
	 * Remove all the inline styling and extra classes.
	 *
	 * @param {Element} element
	 * @return void
	 */

	hideTooltip(element){

		if(!element){

			return;
		}

		// See if element is the tooltip itself, and otherwise, search for a tooltip element.
		if(!element.classList.contains('InfoToolTip')){

			element = element.querySelector('.InfoToolTip');
			if(!element || !element.classList.contains('is-Active')){

				// This tooltip is not active, so no need to close it.
				return;
			}
		}

		// In case a tooltip was generated and there was no other element which triggered it.
		if(element.classList.contains('InfoIcon') || element.classList.contains('InfoToolTip')){

			this.removeCSSClasses(element, ['is-Active', 'NoNose', 'NoseLeft', 'NoseBottom', 'NoseRight']);
		}
	}

	/**
	 * Removes CSS classes from a specified element.
	 *
	 * @param element
	 * @param classes
	 * @return void
	 */

	removeCSSClasses(element, classes){

		if(classes.length === 0){

			return;
		}

		classes.forEach((property) => {

			if(typeof property !== "string"){

				return;
			}

			element.classList.remove(property);
		})
	}
}

export default Tooltip;
<template>
	<nav
		:class="getTarget"
		class="Sidebar">
		<!-- Define SCG paths -->
		<svg
			id="studyportals_logo_small"
			class="defs"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 113 98">
			<path
				d="M12.7 33.5v3.8h6.8v42.9h8V35.6c6.7-2.1 15.2-4 24.1-4.6v49.2h10V31c9
				.6 17.4 2.5 24.2 4.6h-.1v44.6h8V37.3h6.8v-3.8S78.8 23.2 56.4 23.2 12.7 33.5 12.7 33.5z"
			/>
			<path
				d="M100.8 29.4S79.5 19.1 56.7 19.1s-44 10.3-44 10.3v-7.1L56.8 7.6l44 14.7v7.1zm-88.1 55h88.1v7.3H12.7v-7.3z"
			/>
		</svg>
		<!-- /Define SCG paths -->

		<router-link
			:to="{name: 'Design Principles', params: {target: getTarget}}"
			title="Design Principles"
			class="NavLink NavLinkLogo">
			<img
				class="StyleGuideLogo"
				src="http://monet-prtl-co.imgix.net/StyleGuide/StyleGuideLogo.svg"
				alt="Studyportals Styleguide"
				title="Studyportals Styleguide">
		</router-link>

		<ul class="SidebarNavSections">
			<li
				v-for="(section, sectionIndex) in sections"
				:key="`section-${sectionIndex}`"
			>
				<router-link
					:to="{name: section.name, params: {target: getTarget}}"
					:title="section.name"
					class="NavLink">
					<i
						v-if="section.icon"
						:class="section.icon"/>
					{{ section.name }}
				</router-link>
			</li>
		</ul>

		<ul class="ComponentLinks">
			<!-- Render Menu Items From Route -->
			<li
				v-for="(group, index) in menuItems"
				:key="index"
				class="Group"
			>
				<span class="GroupHeader">{{ group.name }}</span>
				<ul>
					<li
						v-for="(item, index) in group.children"
						:key="index"
					>
						<router-link
							:to="{name: item.name, params: {target: getTarget}}"
							class="NavLink">{{ item.name }}</router-link>
					</li>
				</ul>
			</li>
		</ul>

		<ul class="AboutLinks">
			<li class="AboutLink">
				<span class="AboutUs">
					About Us
				</span>
			</li>
			<li class="AboutLink">
				<router-link
					:to="{name: 'Studyportals', params: {target: getTarget}}"
					title="Studyportals"
					class="NavLink NavLinkAbout">
					<svg class="embed"><title>Studyportals</title><use xlink:href="#studyportals_logo_small"/></svg>
				</router-link>
			</li>
			<li class="AboutLink">
				<router-link
					:to="{name: 'DOT', params: {target: getTarget}}"
					title="DOT"
					class="NavLink NavLinkDOT">
					DOT
				</router-link>
			</li>
		</ul>

		<a
			href="https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/BrandBook_v13_22112017_Web.pdf"
			title="Download Brandbook"
			class="NavLink NavLinkDownloadBrandbook"
			download>
			<i class="lnr-download2"/>
			Download Brandbook
		</a>
	</nav>
</template>

<script>
    import store from '../../../../store';
    export default {
        name: 'StyleGuideSidebar',
        data() {
            return {
                sections: [
                    {
                        name: 'Design Principles',
                        icon: 'lnr-magic-wand'
                    },
                    {
                        name: 'Design Direction',
                        icon: 'lnr-magic-wand'
                    },
                    {
                        name: 'User Journey',
                        icon: 'lnr-map2'
                    }
                ],
                menuItems: []
            }
        },
        computed: {
            getTarget () {
                return store.getters.target();
            }
        },
       
		created() {
          
			let hasChildren = [];

            // Get Styleguide Children.
			this.$router.options.routes.forEach(route => {
                if(route.children && route.children.length > 0) {
					hasChildren = hasChildren.concat(route.children)
                }
			});

			// Get Styleguide Main Categories and push into menuItems.
			hasChildren.forEach(route => {
				if(route.children && route.children.length > 0) {
					this.menuItems = this.menuItems.concat({
						name: route.name,
						children: route.children
					})
				}
			});
		}
    }
</script>

<style lang="scss">
    @import '../../../../CSS/_variables.scss';

    $sidebarMargin: 1.5rem;

    svg.defs {
        height: 0;
        width: 0;
        margin: 0;
        padding: 0;
    }

    .Sidebar {
        font-size: 0.875rem;
        padding: 1rem 0 2rem 0;
        box-sizing: border-box;

        ul {
            padding: 0;
        }

        .ComponentLinks {
            padding-left: 5.5rem;
            background: transparent url("http://monet-prtl-co.imgix.net/StyleGuide/BrandIdentityGraphics.svg") no-repeat left 1rem;
        }

        ul ul {
            margin: 0 0 1.5rem 0;
            padding-left: 1.5rem;
        }

        li {
            margin: 0 0 0.5rem 0;
            list-style: none;

            &.Group {
                margin-top: 1rem;

                &:first-of-type{
                    margin-top: 0;
                }
            }

            .GroupHeader {
                display: block;
                margin: 0 0 0.5rem 0;
                color: $Grey;

				@include HeadingSize('H5');
            }
        }

        .NavLink {
            display: block;
            position: relative;
            color: $GreyDD;
            text-decoration: none;

	        @include FontSize('N');

            &:visited {
                color: $GreyDD;
            }

            &.router-link-exact-active,
            &:hover {
                 font-weight: 600;
                 text-decoration: none;
                 color: $GreyDD;
            }

            &.router-link-exact-active {
                cursor: default;
                pointer-events: none;
            }
        }

        .NavLinkLogo {
            text-align: center;
            margin: 0 auto 2rem auto;

            .StyleGuideLogo {
                width: 13rem;
            }
        }

        .SidebarNavSections{
            margin: 0 1.5rem 1.5rem 2rem;

            li {
                margin-bottom: 0.5rem;

                a {
                    display: flex;
                    align-items: center;
                }
            }

            i {
                font-size: 1rem;
                margin-right: 0.5rem;
            }
        }

        .NavLinkDesignPrinciples,
        .NavLinkUserJourney{
            margin: 0 1.5rem 0.5rem 2rem;
            padding-left: 1.5rem;

            i {
                position: absolute;
                top: 5px;
                left: 0;
            }
        }

        .NavLinkUserJourney{
            margin-bottom: 1.5rem;

            i {
                visibility: hidden
            }
        }

        .AboutLinks {
			display: flex;
            padding-left: 0;
            margin: 4rem 1.5rem 2rem 2rem;

            .AboutLink {
                display: inline-block;
				margin-bottom: 0;

				&:first-child {
					flex-grow: 1;
				}
            }

            .AboutUs {
                display: block;
                margin: 0;
                color: $Grey;
	            @include HeadingSize('H5');
                line-height: 3.2rem;
            }

            .NavLink {
                display: inline-block;
                position: relative;
                height: 3rem;
                width: 3rem;
                color: $GreyDD;
				margin-left: 0.5rem;
                line-height: 3rem;
                text-align: center;
                text-decoration: none;
                font-weight: 700;
                border-radius: 3px;
                background: $White;
                transition: background .5s;
                border: 2px solid $GreyDD;

                svg.embed {
                    height: 1.8rem;
                    width: 1.8rem;
                    padding-top: 8px;
                    vertical-align: top;
                    fill: $GreyDD;
                    transition: background .5s;
                }

                img {
                    height: 1.8rem;
                    padding-top: 8px;
                    vertical-align: top;
                }

                i {
                    margin-right: 0;
                    color: $GreyDD;
                    font-size: 1.3rem;
                    vertical-align: middle;
                }

                &:hover {
                    color: $White;
                    font-weight: 700;
                    background: $GreyDD;

                    svg.embed {
                        fill: $White;
                    }
                }

                &.router-link-exact-active {
                    color: $White;
                    font-weight: 700;
                    background: $GreyDD;

                    svg.embed {
                        fill: $White;
                    }
                }
            }
        }

        .NavLinkDownloadBrandbook {
            display: block;
            position: relative;
            margin: 1rem 1.5rem 1rem 2rem;
            padding: 0 0 0 2.3rem;
            line-height: 2.5rem;
            font-size: 1rem;
            border: 2px solid $GreyDD;
            border-radius: 3px;
            transition: background .5s;

            i {
                position: absolute;
                top: 0;
                left: 0;
                width: 2.5rem;
                font-size: 1.3rem;
                line-height: inherit;
                text-align: center;
            }

            &:hover {
                color: $White;
                font-weight: normal;
                background: $GreyDD;
            }
        }
    }
</style>

<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedPurpose/>

	</div>
</template>

<script>
	import SharedPurpose from '../components/Shared/Components/Purpose.vue';
	import store from '../store';

	export default {
		name: 'StyleguidePurpose',
		components: { SharedPurpose },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
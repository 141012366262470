<template>
	<article 
		id="Logo" 
		class="Item col1">

		<!-- Logo downloads (all variants) -->
		<section class="SubItem colblock">
			<h2 class="LogoModeHeader">Meaning and Variations</h2>

			<p>
				The main element of the logo is its threepillared acropolis.
				It represents the portal metaphor used nowadays to signify multiple
				online-based services. It also stands as a symbol for intelligence
				and idealism, resembling the neoclassical architecture style of
				university buildings.
			</p>

			<download-logo 
				:logo="logos.coloured" 
				class="DownloadMainLogo"/>

			<p>
				You can also use variations of the logo if the situation requires a different size proportion:
			</p>

			<download-logos>
				<download-logo 
					v-for="logo in logos.variations" 
					:key="logo.name"
					:logo="logo"
				/>
			</download-logos>

		</section>

		<!-- Logo usage (incl safety area) -->
		<section class="SubItem colblock">
			<h2>Usage</h2>
			<p>
				Give it space. To preserve the full visual impact that Studyportals’
				logo has, always maintain adequate clear space around it. The safety
				area is calculated using the squared shape of the acropolis element.
			</p>

			<download-logo 
				:logo="logos.usage" 
				:border="false" 
				class="UsageLogo"/>
		</section>

		<!-- The application examples (using different backgrounds) -->
		<section class="SubItem colblock">

			<h2>Applications</h2>
			<p>
				Colour applications are integral to our brand identity, and must
				be used thoughtfully and consistently. All coloured versions of
				the logo are the preferred options; use these when the media
				background is white or light-coloured.
			</p>

			<example-wrapper :cappedWidth="true">
				<example-logos>
					<example-logo :logo="logos.coloured"/>
					<example-logo 
						:logo="logos.coloured" 
						:className="containerClasses.grey"/>
				</example-logos>
			</example-wrapper>

			<p>
				The white logo is used for dark-coloured backgrounds. Ideally,
				this version should be affixed to a Brand Orange or Brand Blue.
			</p>

			<example-wrapper :cappedWidth="true">
				<example-logos>
					<example-logo 
						:logo="logos.white" 
						:className="containerClasses.orange"/>
					<example-logo 
						:logo="logos.white" 
						:className="containerClasses.blue"/>
				</example-logos>
			</example-wrapper>

			<p>
				The colour of the logo also depends on the tone of the pictures
				placed in the background. For instance, the coloured logo looks
				better on top of light pictures, while the white logo is best on
				top of dark pictures.
			</p>

			<example-wrapper :cappedWidth="true">
				<example-logos>
					<example-logo 
						:logo="logos.coloured" 
						:className="containerClasses.lightBg"/>
					<example-logo 
						:logo="logos.white" 
						:className="containerClasses.darkBg"/>
				</example-logos>
			</example-wrapper>

			<p>
				The black logo is only used when the printing process is limited.
				That is, when the media or technique required a unique colour,
				such as on a silk screen.
			</p>

			<example-wrapper :cappedWidth="true">
				<example-logos>
					<example-logo :logo="logos.black"/>
					<example-logo 
						:logo="logos.black" 
						:className="containerClasses.grey"/>
				</example-logos>
			</example-wrapper>

		</section>

		<!-- Restriction about logo usage -->
		<section class="SubItem colblock">
			<h2>Restrictions</h2>
			<p>
				Refrain from using dark shapes to support the coloured logo;
				ensure that the background is dark enough when using the white logo.
				As a general suggestion, only place the logo on a white or brand
				palette background, and avoid using any outline. The logo should
				always be presented with the acropolis pointing upwards, and
				should not be split into smaller sections.
			</p>

			<example-wrapper 
				:type="'warning'" 
				:cappedWidth="true" 
				labelText="Don't" 
				labelIcon="lnr-warning">
				<div class="RestrictionLogos">
					<div 
						v-for="donts in logos.donts"
						:key="donts.name"
						class="RestrictionLogo"
					>
						<img 
							:src="donts.src" 
							alt="donts.name">
					</div>
				</div>
			</example-wrapper>
		</section>

	</article>

</template>

<script>
    import ExampleWrapper from '../ExampleWrapper.vue';
	import DownloadLogos from './DownloadLogos.vue';
    import DownloadLogo from './DownloadLogo.vue';
    import ExampleLogos from './ExampleLogos.vue';
    import ExampleLogo from './ExampleLogo.vue';

    export default {
        name: 'Logo',
        components: { DownloadLogos, DownloadLogo, ExampleLogos, ExampleLogo, ExampleWrapper },
        data () {
            return {
                logos: {
                    black: {
                        name: 'Logo - Main black',
                        src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoMainBlack.svg',
                    },
                    white: {
                        name: 'Logo - Main white',
                        src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoMainWhite.svg',
                    },
                    coloured: {
                        name: 'Logo - Main (coloured/white/black)',
                        description: 'Main logo',
                        src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoMainColour.svg',
                        download: 'https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/StudyportalsLogoMain.zip',
                        types: ['EPS', 'PNG']
                    },
                    usage: {
                        name: 'Logo - Usage explanation',
                        description: 'Safety area',
                        src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/UsageLogoSafetyArea.png'
                    },
                    variations: {
                        vertical: {
                            name: 'Logo - Vertical',
                            description: 'Vertical',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoVertical.png',
                            download: 'https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/StudyPortalsLogoVertical.zip',
                            types: ['EPS', 'PNG']
                        },
                        condensed: {
                            name: 'Logo - Condensed',
                            description: 'Condensed',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoCondensed.png',
                            download: 'https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/StudyportalsLogoCondensed.zip',
                            types: ['EPS', 'PNG']
                        },
                        squared: {
                            name: 'Logo - Squared',
                            description: 'Squared',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoAcropolisColour.png',
                            download: 'https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/StudyportalsLogoSquared.zip',
                            types: ['EPS', 'PNG']
                        },
                        orangeSquared: {
                            name: 'Logo - Orange squared',
                            description: 'Orange squared',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/LogoAcropolisOrange.png',
                            download: 'https://s3.eu-central-1.amazonaws.com/studyportals-styleguide/StudyportalsLogoOrangeSquared.zip',
                            types: ['EPS', 'PNG']
                        }
                    },
                    donts: {
                        composition: {
                            name: 'Bad logo application - Composition',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/RestrictionsComposition.png',
                        },
                        colour: {
                            name: 'Bad logo application - Colours',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/RestrictionsColours.png',
                        },
                        noAcropolis: {
                            name: 'Bad logo application - No acropolis',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/RestrictionsNoAcropolis.png',
                        },
                        shadow: {
                            name: 'Bad logo application - Shadows',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/RestrictionsShadow.png',
                        },
                        colourBackground: {
                            name: 'Bad logo application - Coloured background',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/RestrictionsColourBackground.png',
                        },
                        lightBackground: {
                            name: 'Bad logo application - Light background',
                            src: 'http://monet-prtl-co.imgix.net/StyleGuide/Logos/RestrictionsLightBackground.png',
                        }
                    }
                },
                containerClasses: {
                    grey: 'Grey',
                    orange: 'Orange',
                    blue: 'Blue',
                    lightBg: 'LightBg',
                    darkBg: 'DarkBg'
                },
            }
        },
    }
</script>

<style lang="scss">
	@import '../../../../CSS/_variables.scss';

	#Logo{
		h3{
			margin-bottom: $SpaceL;
		}

		.Example{
			padding-left: 0;
		}

		.DownloadMainLogo{
			max-width: 38.5rem;
			.DownloadImage {
				margin-top: -6%;
				margin-bottom: -6%;
			}
		}

		.UsageLogo{
			max-width: 30rem;
			margin-bottom: 0;
		}

		.RestrictionLogos{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.RestrictionLogo{
				flex: 2;
				min-width: 46%;
				margin: 1rem 1rem 1rem 0;
			}
		}
	}
</style>

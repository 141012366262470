import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";

import StyleGuide from './Panes/StyleGuide.vue';

import BrandIdentityRouterView from './Panes/BrandIdentityRouterView.vue';
import ComponentsRouterView from './Panes/ComponentsRouterView.vue';

import Principles from './Panes/_Principles.vue';
import DesignDirection from './Panes/_DesignDirection.vue';
import UserJourney from './Panes/_UserJourney.vue';
import AboutStudyportals from './Panes/_AboutStudyportals.vue';
import DOT from './Panes/_DOT.vue';
import Purpose from './Panes/_Purpose.vue';
import Typography from './Panes/_Typography.vue';
import Colour from './Panes/_Colour.vue';
import Icons from './Panes/_Icons.vue';
import Logo from './Panes/_Logo.vue';
import Imagery from './Panes/_Imagery.vue';
import Writing from './Panes/_Writing.vue';
import GridAndLayout from './Panes/_GridAndLayout.vue';
import Buttons from './Panes/_Buttons.vue';
import Spacing from './Panes/_Spacing.vue';
import Modal from './Panes/_Modal.vue';
import Navigation from './Panes/_Navigation.vue';
import FormElements from './Panes/_FormElements.vue';
import CoverPhotos from './Panes/_CoverPhotos.vue';


const routes = [
	{path: '/', redirect: '/student/principles'},
	{path: '/:target(student|client)', component: StyleGuide, name: 'Styleguide',
		children: [
			{
				path: 'brandidentity', component: BrandIdentityRouterView, name: 'Brand Identity',
				children: [
					{path: 'logo', component: Logo, name: 'Logo'},
					{path: 'icons', component: Icons, name: 'Icons'},
					{path: 'colour', component: Colour, name: 'Colours'},
					{path: 'typography', component: Typography, name: 'Typography'},
					{path: 'writing', component: Writing, name: 'Writing'},
					{path: 'imagery', component: Imagery, name: 'Imagery'}
				]
			},
			{
				path: 'components', component: ComponentsRouterView, name: 'Components',
				children: [
					{path: 'grid', component: GridAndLayout, name: 'Grid & Layout'},
					{path: 'buttons', component: Buttons, name: 'Buttons'},
					{path: 'spacing', component: Spacing, name: 'Spacing'},
					{path: 'navigation', component: Navigation, name: 'Navigation', meta: { earlyPhase: true }},
					{path: 'coverphotos', component: CoverPhotos, name: 'Cover Photos'},
					{path: 'forms', component: FormElements, name: 'Form Elements'},
					{path: 'modal', component: Modal, name: 'Modal'}
				]
			},
			{path: 'purpose', component: Purpose, name: 'Purpose'},
			{path: 'studyportals', component: AboutStudyportals, name: 'Studyportals'},
			{path: 'dot', component: DOT, name: 'DOT'},
			{path: 'principles', component: Principles, name: 'Design Principles'},
			{path: 'userjourney', component: UserJourney, name: 'User Journey'},
			{path: 'design-direction', component: DesignDirection, name: 'Design Direction'}
		]
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {

			return savedPosition
		}
		else if (to.hash) {

			return {
				selector: to.hash
			};
		}
		else {

			return {
				x: 0,
				y: 0
			};
		}
	}
})

export default router;

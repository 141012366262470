<template>
	<div
		v-if="snippets.length > 0 && lang !== ''" 
		class="Wrapper"
	>
		<div class="CodeSnippetWrapper">
			<div class="CodeSnippetsTabs">
				<button
					v-for="snippet in snippets"
					:key="snippet.lang"
					:class="snippet.lang === lang ? 'Active TabButton' : 'TabButton'"
					@click="switchLang(snippet.lang)"
				>
					{{ snippet.label }}
				</button>

				<button 
					v-clipboard="() => clipboardContent"
					class="CopyButton"
					@click="toggleCopied()"
				>
					<i class="lnr-clipboard-text" />
					{{ copied ? "Copied" : "Copy code" }}
				</button>
			</div>
			<div 
				:class="expanded ? 'Expanded CodeSnippetView' : 'CodeSnippetView'"
			>
				<prism :language="lang">{{ clipboardContent.trim() }}</prism>
			</div>
		</div>
		<button
			class="NavigatorButton ExpandButton"
			@click="toggleExpandedSnippets()"
		>
			{{ expanded ? 'Collapse' : 'Expand' }} snippet
		</button>
	</div>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import Prism from "vue-prism-component";


export default {
	name: 'CodeSnippets',
	components: {
		Prism
	},
	props: {
		package_name: {
			type: String,
			default: () => ''
		},
		identifiers: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			snippets: [],
			expanded: false,
			copied: false,
			lang: ''
		};
	},
	computed: {
		clipboardContent() {

			if(this.snippets.length > 0) {

				let matchingSnippet = this.snippets.filter(snippet => {

					return snippet.lang === this.lang;
				});

				return matchingSnippet[0].snippet;
			}

			return '';
		}
	},
	async created() {

		if(this.package_name) {

			await this.scrapeCodeSnippets();
			this.setLanguage();
		}
	},
	methods: {
		async scrapeCodeSnippets() {

			if(this.identifiers.length === 0) {

				const response = await fetch(`https://code-snippets-api.prtl.co/snippets?repository=${this.package_name}`); // eslint-disable-line
				const snippetData = await response.json();
				this.setSnippetData(snippetData);
				return;
			}

			await Promise.all(this.identifiers.map(async(identifier) => {

				const response = await fetch(`https://code-snippets-api.prtl.co/snippets?repository=${this.package_name}&identifier=${identifier}`); // eslint-disable-line
				const snippetData = await response.json();
				this.setSnippetData(snippetData);
			}));

		},
		setSnippetData(snippetData) {

			if(snippetData.snippets.javascript) {

				snippetData.snippets.javascript.forEach(snippet => {

					this.snippets.push({
						lang: "javascript",
						label: "Javascript",
						snippet
					})
				})
			}

			if(snippetData.snippets.scss) {

				snippetData.snippets.scss.forEach(snippet => {

					this.snippets.push({
						lang: "css",
						label: "Styling (SCSS)",
						snippet
					})
				})
			}

			if(snippetData.snippets.html) {
				
				snippetData.snippets.html.forEach(snippet => {

					this.snippets.push({
						lang: "html",
						label: "HTML",
						snippet
					})
				})
			}
		},
		setLanguage() {

			if(this.snippets.length > 0) {

				this.lang = this.snippets[0].lang;
			}
		},
		switchLang(lang) {

			this.lang = lang;
		},
		toggleExpandedSnippets() {

			this.expanded = this.expanded ? false : true;
		},
		toggleCopied() {
			
			this.copied = true;

			setTimeout(() => {

				this.copied = false;
			}, 1000);
		}
	}
};
</script>

<style lang="scss" scoped>
	@import './CSS/_variables.scss';

	.Wrapper {
		display: flex;
		flex-direction: column;

		.ExpandButton {
			margin: 1rem auto;
		}
	}

	.CodeSnippetWrapper {
		box-sizing: border-box;
		max-width: 100%;
		padding: 1rem;

		border: 1px solid $Grey;

		background: $GreyLL;

		.CodeSnippetsTabs {
			display: flex;

			.TabButton,
			.CopyButton {

				padding: 0.3rem 0;
				margin-bottom: 0.5rem;

				background: none;
				border: 0;

				@include FontSize(N);
				color: $GreyD;
			}

			.TabButton {
				margin: 0 1.5rem 0.5rem 0;

				&.Active {
					color: $BrandBlue;

					border-bottom: 2px solid $BrandBlue;
				}
			}

			.CopyButton {
				margin-left:  auto;

				color: $BrandBlue;
			}
		}

		.CodeSnippetView {

			pre[class*="language-"] {
				position: relative;

				min-height: 200px;
				max-height: 200px;
				overflow-y: hidden;
				overflow-x: scroll;

				background: $White;

				transition: max-height 0.5s;

				&:after {
					position: absolute;
					bottom: 0;
					left: 0;

					display: block;
					width: 100%;
					height: 80px;

					background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,  rgba(255,255,255,1) 100%); 

					content: "";
				}
			}

			&.Expanded {

				pre[class*="language-"] {
					height: auto;
					max-height: 1000px;

					&:after {
						background: none;
					}
				}
			}
		}
	}
</style>

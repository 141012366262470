import state from "./state";
const mutations = {
    setTarget: (target) => {
        state.target = target;
    },
    setColourMode: (colourMode) => {
        state.colourMode = colourMode;
    },
    setSidebarClosed: (sidebarClosed) => {
        state.sidebarClosed = sidebarClosed;
    }
};
export default mutations;


import router from './Router';
import App from './Panes/App.vue';
import store from './store';
import { createApp, h } from "vue";

const app = createApp({
	render: () => h(App)
})

router.beforeEach((to, from, next) => {

	if(window.innerWidth < 768){
		store.mutations.setSidebarClosed(true);
	}
	
	next();
});

app.use(router);
app.mount('#App')
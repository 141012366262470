<template>
	<article 
		id="Icons" 
		class="Item col1">
		<section class="SubItem colblock">
			<h2 class="IconsModeHeader HeaderHasSelect">Introduction</h2>
			<select 
				class="PageSettingSelect" 
				@input="changeLabelVisibility($event)">
				<option 
					:selected="labelsVisible === false" 
					value="false">Hide labels</option>
				<option 
					:selected="labelsVisible === true" 
					value="true">Show labels</option>
			</select>
			<p>
				Using icons is a good way to emphasize certain actions and information.
				It's easier for users to spot them on our website and reduce the
				cognitive load. At Studyportals we use the <strong>Linear icons</strong>
				from <strong>IcoMoon</strong> as the base (download the full set
				<a 
					href="/static/SP_icons.zip" 
					target="_blank" 
					title="Full Icon Set">here</a>),
					and extend this set with custom icons when needed.
			</p>
			<p>
				If you want to use an icon and not sure which steps to follow, check our
				<a 
					href="//monet-prtl-co.imgix.net/StyleGuide/Icons/IconWorkflow.png" 
					target="_blank" 
					title="Icon workflow">icon workflow</a>.
			</p>
		</section>

		<section 
			v-for="(group, index) in studyportalsIconSet" 
			:key="index"
			class="SubItem colblock"
		>
			<h2>{{ group.name }}</h2>
			<div class="VisualBlock">
				<div 
					v-for="(icon, index) in group.icons" 
					:key="index"
					class="IconBlock"
				>
					<span 
						v-if="customIcons.indexOf(icon) !== -1" 
						class="CustomLabel">CUS</span>
					<i :class="['Icon', icon]"/>
					<span :class="['IconLabel', !labelsVisible ? 'Invisible' : '']">{{ icon }}</span>
				</div>
			</div>
		</section>
	</article>
</template>

<script>
	import store from '../../../store';
	export default {
		name: 'Icons',
		data () {
			return {
				labelsVisible: true,
				customIcons:[
					'lnr-user-leads',
					'lnr-bot',
					'lnr-bag-coins',
					'lnr-heading2',
					'lnr-clipboard-pencil-crossed',
					'lnr-checkbox_partialinside',
					'lnr-radiobutton_inside',
					'lnr-sp-logo',
					'lnr-flower',
					'lnr-soil',
					'lnr-trunk',
					'lnr-compare',
					'lnr-user-filled-menu',
					'lnr-hand-money',
					'lnr-heart-lock',
					'lnr-map-marker-filled',
					'lnr-trend',
					'lnr-funnel',
					'lnr-bestfit-logo'
				],
				studyportalsIconSet: [
					{
						name: 'People',
						icons: [
							'lnr-user',
							'lnr-user-full',
							'lnr-user-filled-menu',
							'lnr-user-plus',
							'lnr-user-leads',
							'lnr-man',
							'lnr-woman',
							'lnr-users2',
							'lnr-man-woman',
							'lnr-mustache'
						]
					},
					{
						name: 'Disciplines',
						icons: [
							'lnr-tree',
							'lnr-rulers',
							'lnr-earth',
							'lnr-boat',
							'lnr-pen2',
							'lnr-microscope',
							'lnr-magic-wand',
							'lnr-briefcase',
							'lnr-cli',
							'lnr-book2',
							'lnr-camera',
							'lnr-balance',
							'lnr-first-aid',
							'lnr-beaker'
						]
					},
					{
						name: 'Actions',
						icons: [
							'lnr-menu',
							'lnr-star',
							'lnr-star-full',
							'lnr-heart',
							'lnr-heart-full',
							'lnr-magnifier',
							'lnr-launch',
							'lnr-play',
							'lnr-chevron-left',
							'lnr-chevron-right',
							'lnr-chevron-down',
							'lnr-chevron-up',
							'lnr-arrow-up',
							'lnr-arrow-down',
							'lnr-arrow-right',
							'lnr-arrow-left',
							'lnr-check',
							'lnr-cross',
							'lnr-minus',
							'lnr-plus',
							'lnr-plus-inverted',
							'lnr-eye-crossed',
							'lnr-eye',
							'lnr-link',
							'lnr-link2',
							'lnr-unlink',
							'lnr-cog',
							'lnr-forward-circle',
							'lnr-redo2',
							'lnr-sync',
							'lnr-floppy-disk',
							'lnr-printer',
							'lnr-download2',
							'lnr-pencil',
							'lnr-trash2',
							'lnr-plus-square',
							'lnr-heading1',
							'lnr-heading2',
							'lnr-text-format-remove',
							'lnr-bold',
							'lnr-italic',
							'lnr-underline',
							'lnr-strikethrough',
							'lnr-pilcrow',
							'lnr-list',
							'lnr-list2',
							'lnr-list4',
							'lnr-equalizer',
							'lnr-ellipsis',
							'lnr-share'
						]
					},
					{
						name: 'Organic',
						icons: [
							'lnr-sp-logo',
							'lnr-speed-slow',
							'lnr-speed-medium',
							'lnr-speed-fast',
							'lnr-hand-money',
							'lnr-wallet',
							'lnr-select2',
							'lnr-heart-lock',
							'lnr-cloud-check',
							'lnr-road-sign',
							'lnr-bullhorn',
							'lnr-compass2',
							'lnr-medal-narrow',
							'lnr-lock',
							'lnr-key',
							'lnr-home2',
							'lnr-home3',
							'lnr-university',
							'lnr-cactus',
							'lnr-apartment',
							'lnr-book',
							'lnr-library',
							'lnr-papers',
							'lnr-document',
							'lnr-clipboard-check',
							'lnr-clipboard-text',
							'lnr-clipboard-pencil-crossed',
							'lnr-clipboard-alert',
							'lnr-reviews',
							'lnr-bubble',
							'lnr-bubble-heart',
							'lnr-languagelevel',
							'lnr-bubbles',
							'lnr-paper-plane',
							'lnr-traffic-lights',
							'lnr-alarm2',
							'lnr-clock',
							'lnr-clock3',
							'lnr-calendar-full',
							'lnr-flag',
							'lnr-lamp',
							'lnr-ghost2',
							'lnr-bug',
							'lnr-envelope',
							'lnr-graduation-hat',
							'lnr-camera',
							'lnr-map-marker',
							'lnr-map-marker-filled',
							'lnr-bot',
							'lnr-map2',
							'lnr-align-bottom',
							'lnr-lock',
							'lnr-license',
							'lnr-license2',
							'lnr-picture',
							'lnr-chart-growth',
							'lnr-graph',
							'lnr-papers',
							'lnr-file-zip',
							'lnr-file-image',
							'lnr-file-charts',
							'lnr-file-stats',
							'lnr-file-spreadsheet',
							'lnr-cart',
							'lnr-bread',
							'lnr-trophy2',
							'lnr-podium',
							'lnr-gift',
							'lnr-profile',
							'lnr-laptop',
							'lnr-thumbs-up',
							'lnr-alarm',
							'lnr-leaf',
							'lnr-flower',
							'lnr-soil',
							'lnr-trunk',
							'lnr-receipt',
							'lnr-archery',
							'lnr-compare',
							'lnr-cash-euro',
							'lnr-laptop-phone'
						]
					},
					{
						name: 'Info',
						icons: [
							'lnr-trend',
							'lnr-funnel',
							'lnr-percent',
							'lnr-question-circle',
							'lnr-info',
							'lnr-warning',
							'lnr-checkmark-circle',
							'lnr-notification-circle',
							'lnr-notification-inverted',
							'lnr-prohibited',
							'lnr-warning',
							'lnr-quote-open',
							'lnr-quote-close',
							'lnr-site-map',
							'lnr-bestfit-logo'
						]
					},
					{
						name: 'Social Media',
						icons: [
							'lnr-facebook',
							'lnr-google-plus',
							'lnr-youtube',
							'lnr-twitter',
							'lnr-linkedin',
							'lnr-instagram'
						]
					},
					{
						name: 'Other:',
						icons: [
							'lnr-checkbox_partialinside',
							'lnr-radiobutton_inside'
						]
					}
				]
			}
		},
		computed: {
			getTarget() {

				return store.getters.target();
			}
		},
		methods:{

			changeLabelVisibility(event){

				const visibilitySelect = event.target;

				if(!visibilitySelect){

					return;
				}

				this.labelsVisible = (visibilitySelect.value === 'true');
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	#Icons{

		.IconsModeHeader{
			display: inline-block;
		}

		.IconBlock{
			position: relative;
			display: inline-block;
			text-align: center;
			width: 7.5rem;
			vertical-align: top;
			margin-bottom: 2rem;

			.Icon{
				display: block;
				font-size: 2rem;
				padding: 0.25rem;
				margin: 0 auto;
			}

			.IconLabel{
				display: block;
				margin-top: 0.5rem;
				line-height: 1.5rem;
				height: 3rem;
				@include FontSize('N');

				&.Invisible{
					visibility: hidden;
				}
			}

			.CustomLabel{
				position: absolute;
				background-color: $Yellow;
				color: $White;
				font-size: 0.6rem;
				line-height: 1rem;
				padding: 0 0.1rem;
				left: 1.25rem;
			}
		}
	}
</style>

<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedUserJourney/>
	</div>
</template>

<script>
	import SharedUserJourney from '../components/Shared/Components/UserJourney.vue';
	import store from '../store';

	export default {
		name: 'UserJourney',
		components: { SharedUserJourney },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
<template>
	<div 
		:class="classObject" 
		class="Example">
		<span class="ExampleLabel">
			<i 
				v-if="labelIcon" 
				:class="labelIcon"/>
			{{ labelText }}
		</span>
		<div class="ExampleWrapper">
			<slot/>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			labelText: {
                type: String,
				default: 'Example'
			},
			labelIcon:{
				type: String,
				default: ''
			},
            type: {
                type: String,
                default: 'default'
            },
			cappedWidth: {
			    type: Boolean,
				default: false
			}
		},
	    data() {
	      return {

		  }
		},
		computed: {
	      classObject() {
	          return {
				  'ExampleTypeWarning': this.type === 'warning',
				  'ExampleTypeVariations': this.type === 'variations',
	              'ExampleCappedWidth': this.cappedWidth
			  }
		  }
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	.Example{
		margin: 3rem 0;

		.ExampleLabel{
			background-color: $Grey;
			color: $White;
			padding: 0.3rem 1rem;

			@include FontSize('SN');
		}

		.ExampleWrapper{
			color: $Grey;
			border-left: 5px solid $Grey;
			padding: 0.5rem 1rem;
			margin: 1rem 0;

			> p:last-of-type{
				margin: 0;
			}
		}

		&.ExampleCappedWidth{
			.ExampleWrapper{
				max-width: 28rem;
			}
		}

		&.ExampleTypeWarning{
			.ExampleLabel{
				background-color: $Red;
			}

			.ExampleWrapper{
				color: $Red;
				border-left-color: $Red;
			}
		}

		&.ExampleTypeVariations{
			.ExampleLabel{
				background-color: $Blue;
			}

			.ExampleWrapper{
				color: $GreyDD;
				border-left-color: $Blue;
			}
		}
	}
</style>
<template>
	<div :class="['Colour', index === 0 ? 'ColourFirst' : '']">
		<!-- For the first colour block in every colour group of three, and all single colour blocks, show the colour values at the top -->
		<div 
			v-if="!index" 
			class="ColourValuesWrapper Top">
			<span v-if="colour.name">{{ colour.name }}</span>
			<span v-if="colour.rgb">{{ getColourMode === 'print' && colour.cmyk ? colour.cmyk : colour.rgb }}</span>
		</div>

		<!-- A coloured circular surface that visualises the colour -->
		<div 
			:class="['ColourVisual', colour.name === 'White' ? 'BorderVisible' : '']"
			:style="`background: ${getColourMode === 'print' && colour.cmyk ? colour.cmyk : colour.rgb}`"/>

		<!-- For the second and third colour block in every colour group, show the colour values on the right side -->
		<div 
			v-if="index" 
			class="ColourValuesWrapper">
			<span v-if="colour.name">{{ colour.name }}</span>
			<span v-if="colour.rgb">{{ getColourMode === 'web' ? colour.rgb : colour.cmyk }}</span>
		</div>
	</div>
</template>

<script>
	import store from '../../../../store';
	export default {
		name: 'VisualizeColour',
		props: {
			colour: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				default: null
			}
		},
		data () {

			return {
				msg: ''
			}
		},
		computed: {
			getColourMode() {

				return store.getters.colourMode();
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../../CSS/_variables.scss';

	.Colour {
		flex: 1;
		display: inline-block;

		.ColourVisual {
			vertical-align: top;
			width: 6.5rem;
			height: 6.5rem;
			margin-right: 0.5rem;
			border-radius: 50%;

			&.BorderVisible{
				border: 1px solid $Grey;
			}
		}

		.ColourValuesWrapper{
			display: inline-block;

			&.Top{
				margin-bottom: 1rem;
			}
		}

		span {
			display: block;
			@include FontSize('N');
		}
	}

	.PrimaryColours{
		.Colour{
			.ColourVisual{
				width: 6.5rem;
				height: 6.5rem;
			}

			&.ColourFirst{
				.ColourVisual{
					width: 13.5rem;
					height: 13.5rem;
				}
			}
		}
	}

	.SecondaryColours{
		.Colour{
			.ColourVisual{
				width: 3rem;
				height: 3rem;
			}

			&.ColourFirst{
				.ColourVisual{
					width: 6.5rem;
					height: 6.5rem;
				}
			}
		}
	}
</style>

<template>
	<article 
		id="Typography" 
		class="Item col1">
		<section 
			id="Typeface" 
			class="SubItem colblock">
			<h2>Typeface</h2>
			<p>
				We associate our tone of voice with the humanist sans serif
				typefaces. In search of a friendly, inspiring, and readable
				typeface among humanist typefaces; we chose Open Sans. Open
				Sans is designed with an upright stress, open forms and a neutral,
				yet friendly appearance. It is optimized for print, web, and
				mobile interfaces, and has excellent legibility characteristics
				in its letterforms.
			</p>
			<div class="TypefaceExample">
				<img 
					class="AlphabetTitle"
					src="http://monet-prtl-co.imgix.net/StyleGuide/Typography/StudyPortals_Alphabet_Title_174x104.png"
					alt="Font Alphabet Title">
				<img 
					class="Alphabet"
					src="http://monet-prtl-co.imgix.net/StyleGuide/Typography/StudyPortals_Alphabet_684x120.png"
					alt="Font Alphabet">
			</div>
		</section>
		<section 
			id="CharactersPerLine" 
			class="SubItem colblock">
			<h2>Characters per Line</h2>
			<p>
				In order to make our content easier to read, we recommend having
				not more than 90 characters per line for XL and L breakpoints,
				60 characters per line for M breakpoint and 45 characters per
				line for S breakpoint.
			</p>
		</section>
		<section 
			id="MainPageHeadings" 
			class="SubItem colblock">
			<h2>Page Headings</h2>
			<p>
				We encourage a hierarchical structure with a main heading (page heading)
				and a set of additional headlines (content headings), as they are depicted
				below. As you can see, in some cases there is a good reason to deviate
				from the default font styling. The article page is an example of this,
				but we try to limit deviations like this as much as possible.
			</p>
			<p>
				We chose our font-sizes based on a modular scale where the ratio is 1.125.
			</p>
			<ul class="HeadingList">
				<div 
					v-for="(headingSizeMain, index) in headingSizesMain"
					:key="index"
				>
					<li class="HeadingMain">
						<div class="HeadingsDescription">
							<span>
								{{ headingSizeMain.description }}
							</span>
						</div>
						<div :class="['HeadingsProperties', headingSizeMain.className]">
							<span>
								{{ headingSizeMain.properties }}
							</span>
							<span 
								v-if="headingSizeMain.note" 
								class="HeadingNote">
								{{ headingSizeMain.note }}
							</span>
						</div>
					</li>
					<li 
						v-for="(headingSizeAlternative, index) in headingSizeMain.alternatives" 
						:key="index"
						class="HeadingAlternative"
					>
						<div class="HeadingsDescription">
							<span>
								{{ headingSizeAlternative.description }}
							</span>
						</div>
						<div :class="['HeadingsProperties', headingSizeAlternative.className]">
							<span>
								{{ headingSizeAlternative.properties }}
							</span>
						</div>
					</li>
				</div>
			</ul>
		</section>
		<section 
			id="BodyText" 
			class="SubItem colblock">
			<h2>Body Text</h2>
			<ul class="HeadingList">
				<li 
					v-for="(bodyTextSize, index) in bodyTextSizes"
					:key="index"
				>
					<div class="HeadingsDescription">
						<span>
							{{ bodyTextSize.description }}
						</span>
					</div>
					<div :class="['HeadingsProperties', bodyTextSize.className]">
						<span>
							{{ bodyTextSize.properties }}
						</span>
					</div>
				</li>
			</ul>
		</section>

		<section 
			id="ExampleTypography" 
			class="SubItem colblock">
			<example-wrapper>
				<div class="TagWrapper">
					<h3 class="TagContent SecondHeading">
						About
					</h3>
					<div class="TagDescription">
						<span>First Headline</span>
					</div>
				</div>
				<div class="TagWrapper">
					<p class="TagContent">
						Interaction Design shapes the experiences of people as they
						interact with products in order to achieve their goals and objectives.
						Interaction designers define product behaviour, mediating
						relationships between people and people, people and products,
						people and environments, and people and services across a variety
						of social, cultural and technological contexts.
					</p>
					<div class="TagDescription">
						<span>Body</span>
					</div>
				</div>
				<div class="TagWrapper">
					<h4 class="TagContent ThirdHeading">
						Priorities in Focus
					</h4>
					<div class="TagDescription">
						<span>Second Headline</span>
					</div>
				</div>
				<div class="TagWrapper">
					<p class="TagContent">
						The Interaction Design course differentiates itself from other
						Interaction Design and HCI programs by addressing worldwide-recognized
						as well as European Union’s priorities, highlighted by the EU’s
						Digital Agenda.
					</p>
					<div class="TagDescription">
						<span>Body</span>
					</div>
				</div>
				<div class="TagWrapper">
					<h4 class="TagContent SecondHeading">
						Career Options
					</h4>
					<div class="TagDescription">
						<span>First Headline</span>
					</div>
				</div>
				<div class="TagWrapper">
					<p class="TagContent">
						<span>
							Graduates can pursue careers as Content Strategists, Creative
							Directors, Creative Technologists, Design Strategists, Developers
							(front-end or back-end), Information Architects, Interaction Designers,
							Mobile Designers, Service Designers, Usability Specialists, User
							Interface Designers and many more.
						</span>

						<span>
							Graduates are also able to excel as entrepreneurs and prepared for
							self-employability through successful pitching and presentation
							of their’ ideas and work to professional and public bodies as well
							as pursuing suitable funding opportunities.
						</span>
					</p>

					<div class="TagDescription">
						<span>Body</span>
					</div>
				</div>
				<div class="TagWrapper">
					<h4 class="TagContent FifthHeading">
						Specific Skills the Graduates Will Acquire
					</h4>
					<div class="TagDescription">
						<span>Fourth Headline</span>
					</div>
				</div>
				<div class="TagWrapper">
					<p class="TagContent">
						The graduates will be knowledgeable about the techniques necessary
						for the design and development of easy to use, appealing and
						thoroughly designed software and systems. Also, they will understand
						how digital media, as products and services, relate to existing
						social, cultural, economic and technological systems to effectively
						lead and collaborate in multi-disciplinary teams through the entire
						process from design to development and evaluation of software and
						systems.
					</p>
					<div class="TagDescription">
						<span>Body</span>
					</div>
				</div>
			</example-wrapper>
		</section>

	</article>
</template>

<script>
	import ExampleWrapper from './ExampleWrapper.vue';
	import StyleGuideLabel from './StyleGuideLabel.vue';

	export default {
		name: 'Typography',
		components: {
			StyleGuideLabel,
			ExampleWrapper
		},
		data () {
			return {
				headingSizesMain: [
					{
						description: 'Main Headline H1',
						properties: 'Regular 34px, Line 48px',
						className: 'FirstHeading',
						alternatives:[
							{
								description: 'Super Hero',
								properties: 'Regular 69px, Line 88px',
								className: 'SuperHero'
							},
							{
								description: 'Landing Hero',
								properties: 'Regular 43px, Line 56px',
								className: 'LandingHero'
							}
						]
					},
					{
						description: 'First Headline H2',
						properties: 'Regular 27px, Line 40px',
						className: 'SecondHeading'
					},
					{
						description: 'Second Headline H3',
						properties: 'Regular 21px, Line 32px',
						className: 'ThirdHeading'
					},
					{
						description: 'Third Headline H4',
						properties: 'Regular 19px, Line 24px',
						className: 'FourthHeading',
						alternatives:[
							{
								description: 'First Article',
								properties: 'Regular 27px, Line 40px',
								className: 'SecondHeading'
							}
						]
					},
					{
						description: 'Fourth Headline H5',
						properties: 'Semibold 17px, Line 24px',
						className: 'FifthHeading',
						alternatives:[
							{
								description: 'Second Article',
								properties: 'Regular 21px, Line 32px',
								className: 'ThirdHeading'
							}
						]
					},
					{
						description: 'Fifth Headline H6',
						properties: 'Semibold 15px, Line 24px',
						note: '(suggested as fallback)',
						className: 'SixthHeading'
					}
				],
				bodyTextSizes: [
					{
						description: 'Body',
						properties: 'Regular 15px, Line 24px',
						className: 'ContentBody'
					},
					{
						description: 'Highlighted body',
						properties: 'Semibold 15px, Line 24px',
						className: 'ContentBodyBold'
					},
					{
						description: 'Footnote',
						properties: 'Regular 13px, Line 16px',
						className: 'ContentFootnote'
					},
					{
						description: 'Label',
						properties: 'Regular 11px, Line 16px',
						className: 'ContentLabel'
					}
				]
			}
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	#Typography{
		width: 100%;

		h3{
			margin-bottom: $SpaceL;
		}

		h4{
			margin-bottom: $SpaceS;
		}

		ul{
			margin: 0;
		}

		.HeadingList{
			list-style: none;
			width: 100%;
			padding: 0;

			li{
				display: block;
				width: auto;

				@include Breakpoint(Large, ExtraLarge) {
					display: flex;
				}

				.HeadingsDescription{
					min-width: 12rem;

					span{
						vertical-align: middle;
					}
				}

				.HeadingsProperties{

					span{
						vertical-align: middle;
					}
				}

				&.HeadingMain{

					// Extra note for this particular heading styling.
					.HeadingNote{
						font-weight: 400;
						color: $GreyD;
					}
				}

				&.HeadingAlternative{
					@include FontSize('SN');
					color: $GreyDD;
					margin: 0;
					padding-left: 1.5rem;

					.HeadingsDescription{
						min-width: 10.5rem; // 12 (min-width) - 1.5 (left padding)
						color: $GreyD;
					}
				}
			}
		}

		.FirstHeading{
			@include HeadingSize('H1');
		}

		.SuperHero{
			@include HeadingSize('H1-hero');
		}

		.LandingHero{
			@include HeadingSize('H1-cover');
		}

		.SecondHeading{
			@include HeadingSize('H2');
		}

		.ThirdHeading{
			@include HeadingSize('H3');
		}

		.FourthHeading{
			@include HeadingSize('H4');
		}

		.FifthHeading{
			@include HeadingSize('H5');
		}

		.SixthHeading{
			@include HeadingSize('H6');
		}
	}

	#Typeface{
		width: 100%;
		max-width: 100%;

		.TypefaceExample{
			margin-top: $SpaceN;
			width: 24rem;

			.AlphabetTitle{
				width: 6rem;
				margin-bottom: 2rem;
			}

			.Alphabet{
				width: 20rem;
			}
		}
	}

	#MainPageHeadings{
		width: 100%;
		min-width: 100%;

		.HeadingList{

			// Extra margin to distinguish groups of headings.
			margin-top: $SpaceN;

			li{
				.HeadingsDescription span, .HeadingsProperties span{
					line-height: 3.5rem;
				}

				&.HeadingMain{
					margin-top: 2rem;
				}
			}

			// Use a different line-height for the two biggest font-sizes to ensure vertical centering works.
			@include Breakpoint(Large, ExtraLarge) {
				li {
					&:nth-child(2) {
						line-height: 6rem;
					}

					&:nth-child(3) {
						line-height: 4rem;
						// After the last main heading deviation the headlines start, so create some extra spacing.
						margin-bottom: $SpaceN;
					}
				}
			}
		}
	}

	#BodyText{

		h3{
			// Compensate for the spacing created by vertically centering the first element.
			margin-bottom: calc(#{$SpaceL} - 0.5rem);
		}

		.HeadingList{

			li{
				.HeadingsDescription span, .HeadingsProperties span{
					line-height: 2.5rem;
				}
			}

			.ContentBody{
				@include FontSize('N');
			}

			.ContentBodyBold{
				@include HeadingSize('H6');
			}

			.ContentFootnote{
				@include FontSize('SN');
			}

			.ContentLabel{
				@include FontSize('S');
			}
		}
	}

	#ExampleTypography{

		.TagWrapper{
			margin-bottom: 1rem;
			display: flex;
			align-items: stretch;

			.TagContent{
				color: $GreyDD;
				margin-bottom: 0.5rem;
				padding-right: 2.5rem;
				display: inline-block;
				flex-basis: 70%;

				span:first-child{
					display: block;
					margin-bottom: 0.5rem;
				}
			}

			h3, h4{
				margin-bottom: 0.5rem;
				max-width: 30rem;
			}

			.TagDescription{
				display: flex;
				color: $GreyDD;
				border-left: 5px solid $GreyDD;
				padding: 0 0 0 2rem;
				flex-basis: 30%;

				span{
					align-self: center;
				}
			}
		}
	}
</style>


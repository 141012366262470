<template>
	<div class="ExampleLogos">
		<div 
			:class="classObject" 
			class="ExampleLogoWrapper">
			<slot/>
		</div>
	</div>
</template>

<script>
	export default {
	    props: {
			hasBorder: {
	            type: Boolean,
				default: true
			}
		},
		computed: {
            classObject() {
                return {
                    'ExampleLogoWrapperBordered': this.hasBorder,
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../../../CSS/_variables.scss';

	.ExampleLogos {

		.ExampleLogoWrapper {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			&.ExampleLogoWrapperBordered {
				border: 1px solid $Grey;
			}
		}
	}
</style>
<template>
	<div class="DownloadLogo">
		<div 
			:class="{ WrapperBorder: border, DownloadHasFile: logo.download }" 
			class="DownloadLogoWrapper">
			<img 
				:src="logo.src" 
				:alt="logo.name" 
				class="DownloadImage">
			<div 
				v-if="logo.types && logo.types.length" 
				class="DownloadControls">
				<i class="lnr-download2"/><a 
					:href="logo.download" 
					class="DownloadLink"><span class="DownloadLabel">Download</span></a>
				<div class="DownloadTypes">{{ getTypesFormatted() }}</div>
			</div>
		</div>
		<p 
			v-if="logo.description" 
			class="LogoDescription">
			{{ logo.description }}
		</p>
	</div>
</template>

<script>
    export default {
        name: 'VisualizeLogo',
        props: {
            logo: {
                type: Object,
                required: true
            },
			border: {
                type: Boolean,
				default: true
			}
        },
		methods: {
			getTypesFormatted() {
				return '(' + this.logo.types.join(' / ') + ')';
			}
		}
    }
</script>

<style lang="scss" scoped>
	@import '../../../../CSS/_variables.scss';

	.DownloadLogo{
		min-width: 8rem;
		margin-top: 3rem;
		margin-bottom: 3rem;

		.DownloadLogoWrapper{
			margin-bottom: 1rem;
			position: relative;
			overflow: hidden;

			&.WrapperBorder{
				border: 1px solid $Grey;
			}
		}

		.DownloadHasFile{
			&:hover{
				.DownloadControls{
					display: block;
				}
				.DownloadImage{
					opacity: 0.5;
					transition: opacity 500ms;
				}
			}
		}

		.DownloadControls{
			display: none;
			padding: 0.5rem 1rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			border: 1px solid $BrandBlue;
			border-radius: 0.2rem;
			background-color: $White;
			@include FontSize('SN');

			.DownloadLink{
				display: inline;
			}

			.DownloadLabel{
				margin-left: 0.2rem;
			}

			.DownloadTypes{
				@include FontSize('SN');
			}
		}

		.LogoDescription{
			margin-bottom: 1rem;
			text-align: center;
			max-width: none;

			@include FontSize('SN');
		}
	}
</style>

<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedTypography/>
	</div>
</template>

<script>
    import SharedTypography from '../components/Shared/Components/Typography.vue';
    import store from '../store';   

    export default {
        name: 'VisualTypography',
        components: { SharedTypography },
        computed: {
            getTarget () {
                return store.getters.target();
            }
        },
    }
</script>
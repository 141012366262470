<template>
	<section 
		id="CoverPhotosOverlays" 
		class="SubItem colblock">
		<h2>Overlays</h2>
		<p>
			We use overlays on the cover photos when we want to add some content to them. The overlays can have the
			color of BrandOrange with 80% opacity or BrandBlue with 80% opacity, and the shape of diagonal or
			rectangular.
		</p>

		<figure class="VisualBlock VisualBlockUseCases">
			<img 
				class="UseCaseChart" 
				src="//monet-prtl-co.imgix.net/StyleGuide/Components/CoverPhotos/cover-photos-use-case-chart.png">
			<figcaption class ="ImageCaption">Use cases for colors and overlay shapes</figcaption>
		</figure>
	</section>
</template>

<script>
    export default {
        name: "Overlays"
    }
</script>

<style lang="scss">
    #CoverPhotosOverlays{
        .VisualBlockUseCases{
            max-width: 336px;
            display: block;
        }
    }
</style>

<template>
	<div id="galactus">
		<SharedCoverPhotos/>
	</div>
</template>

<script>
    import SharedCoverPhotos from '../components/Shared/Components/CoverPhotos/CoverPhotos.vue';

    export default {
        name: 'CoverPhotosView',
        components: { SharedCoverPhotos }
    }
</script>

<template>
	<header 
		id="StyleGuideHeader" 
		:class="getTarget">
		<div class="SwitcherWrapper">
			<StyleSwitcher/>
		</div>
		<h1 class="SuperHero">{{ $route.name }}</h1>
		<Tooltip 
			v-if="$route.meta.earlyPhase"
			tooltip-text="This section is still in its infancy! We encourage you to try out our suggestions,
				experiment with them, and give critical feedback to DOT."
		>
			&#128035;
		</Tooltip>
	</header>
</template>

<script>
	import StyleSwitcher from '../StyleGuide/Switcher.vue';
	import Tooltip from '../../Patterns/Tooltip/Tooltip.vue';
	import store from '../../../../store'

	export default {
		name: 'StyleGuideHeader',
		components: { StyleSwitcher, Tooltip },
		computed: {
			getTarget () {

				return store.getters.target();
			}
		},
		 created () {

				let MainContainer = document.getElementsByClassName('MainContainer');
				if(MainContainer.length > 0){

					MainContainer[0].addEventListener('scroll', this.handleScroll);
				}
		},
		methods:{
			handleScroll(){

				 const distanceY = window.pageYOffset || document.documentElement.scrollTop,
					 shrinkOn = 200;
				 let headerEl = document.getElementsByClassName('HeaderWrapper');

				 if(headerEl.length > 0){

					 headerEl = headerEl[0];
				 }

				 if (distanceY > shrinkOn) {

					 headerEl.classList.add("smaller");
				 } else {

					 headerEl.classList.remove("smaller");
				 }
			}
		 },
	}
</script>

<style lang="scss">
	@import '../../../../CSS/_variables.scss';

	#StyleGuideHeader{
		height: auto;
		padding: $SpaceXL $SpaceXL $SpaceN $SpaceXL;

		h1 {
			display: block;
			margin: 0 0 0 1rem;

			@include HeadingSize('H1-hero');
		}

		.SwitcherWrapper {
			display: block;
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		.SuperHero{
			display: inline-block;
			vertical-align: middle;
		}

		.InfoIcon{
			display: inline-block;
			vertical-align: middle;
			margin-left: 2rem;
			border: 1px solid $GreyLL;
			box-sizing: border-box;
			font-size: 1.5rem;
			line-height: 3rem;
			text-align: center;
			width: 3rem;
			flex-shrink: 0;

			&:hover, &:focus{
				background: $GreyLL;
			}
		}

		@include Breakpoint(Small){
			padding: $SpaceXL $SpaceS $SpaceN $SpaceS;

			h2{
				font-size: 3rem;
				line-height: 4rem;
			}

		}

		@include Breakpoint(Medium){
			padding: $SpaceXL $SpaceN $SpaceN $SpaceN;

			h2{
				font-size: 3rem;
				line-height: 4rem;
			}

		}

		@include Breakpoint(Large){
			padding: $SpaceXL $SpaceL $SpaceN $SpaceL;
		}
	}
</style>

<template>
	<article 
		id="Writing" 
		class="Item col1">
		<section class="SubItem colblock">
			<h2>3 Basic Rules</h2>
			<ol class="ThreeWritingRules">
				<li>Be consistent in spelling, tone of voice, and structure.</li>
				<li>Clarity is more important than grammar.</li>
				<li>Spend as much time revising and revising as you do creating and composing.</li>
			</ol>
		</section>

		<section class="SubItem colblock">
			<h2>Tone of Voice</h2>
			<p>
				What feelings are you conveying in the content you’re writing?
				How does it come across to the user? The tone for all written content
				must meet the following conditions:
			</p>

			<div class="VisualBlock">
				<div class="ToneOfVoiceWrapper">
					<div class="ToneOfVoiceShape">
						<p class="ShapeLabel">Friendly</p>
						<img 
							src="http://monet-prtl-co.imgix.net/StyleGuide/Writing/FriendlyShape.svg"
							alt="Friendly" 
							class="ShapeImg">
					</div>

					<p>
						Tell jokes! Say something simple, personable, and uncomplicated.
					</p>

					<example-wrapper>
						<p>
							Aerospace Engineering is a fancy way of saying ‘rocket science’.
							So, when you tell people it’s a difficult field and they scoff, saying,
							"hey, it isn't rocket science," you’re welcome to scoff in return and say,
							“Actually...it is!".
						</p>
					</example-wrapper>
				</div>
				<div class="ToneOfVoiceWrapper">
					<div class="ToneOfVoiceShape">
						<p class="ShapeLabel">Inspiring</p>
						<img 
							src="http://monet-prtl-co.imgix.net/StyleGuide/Writing/InspiringShape.svg"
							alt="Inspiring" 
							class="ShapeImg">
					</div>
					<p>
						Write enthusiastically in a way that makes the user feel empowered and
						joyful about what he/she is doing.
					</p>
					<example-wrapper>
						<p>
							Once you successfully graduate from an M.B.A.,
							business people will start to see how ambitious and talented you already are.
						</p>
					</example-wrapper>
				</div>
				<div class="ToneOfVoiceWrapper">
					<div class="ToneOfVoiceShape">
						<p class="ShapeLabel">Authoritative</p>
						<img 
							src="http://monet-prtl-co.imgix.net/StyleGuide/Writing/AuthoritativeShape.svg"
							alt="Authoritative" 
							class="ShapeImg">
					</div>
					<p>
						Write like you know what you’re talking about. We know what users need to do,
						we don’t think we know what users need to do.
					</p>

					<example-wrapper>
						<p>
							Each school and program has unique admission requirements; so,
							you’ll need to research those carefully to see if you’re eligible.
							Be sure that you give yourself plenty of time to research and apply;
							and it’s important to know how different deadlines may impact your process.
						</p>
					</example-wrapper>
				</div>
			</div>
		</section>

		<section class="SubItem colblock">
			<h2>Point of View</h2>

			<p>
				Who is being addressed and who is speaking? This is clarified through point of view.
			</p>
			<p>
				In the case of our online content, we write in second-person point of view.
				We address the user as ‘you’, and we take you on a journey through our platform.
			</p>

			<example-wrapper>
				<p>
					Aerospace Engineering is a fancy way of saying ‘rocket science’.
					So, when you tell people it’s a difficult field and they scoff, saying,
					"hey, it isn't rocket science," you’re welcome to scoff in return and say,
					“Actually...it is!".
				</p>
			</example-wrapper>

			<p>
				Links and buttons represent actions performed by the user, so in these cases the copy may be written
				in a first person point of view.
			</p>

			<example-wrapper>
				<div class="ButtonsExample">
					<button 
						type="button" 
						class="Button">I want to participate</button>
					<button 
						type="button" 
						class="Button Blue">Register</button>
					<button 
						type="button" 
						class="Button Ghost Blue">Log in</button>
				</div>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2>Spelling</h2>
			<p class="SpellingInformation">
				We use British English spelling in our writing.
			</p>

			<example-wrapper class="SpellingExamples">
				<ul class="EnglishList">
					<li>
						<h6>British English</h6>
					</li>
					<li 
						v-for="(item, index) in britishEnglish"
						:key="index"
					>
						<span class="Spelling">{{ item }}</span>
					</li>
				</ul>
			</example-wrapper>

			<example-wrapper 
				:type="'warning'" 
				labelText="Don't" 
				labelIcon="lnr-warning" 
				class="SpellingExamples">
				<ul class="EnglishList Warning">
					<li>
						<h6>American English</h6>
					</li>
					<li 
						v-for="(item, index) in americanEnglish"
						:key="index"
					>
						<span class="Spelling">{{ item }}</span>
					</li>
				</ul>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2>Labelling</h2>
			<p>
				We write Master's and Bachelor's always with an apostrophe.
			</p>
			<example-wrapper>
				<p>
					Are you looking for your Master’s degree?<br>
					Are you enrolled in a Bachelor’s degree?
				</p>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2>Abbreviations</h2>
			<p>
				We keep the dots while writing the degree types.
			</p>

			<example-wrapper>
				<div class="DegreeTypesLabels">
					<style-guide-label 
						v-for="(degree, index) in degreeTypes"
						:key="index"
						:labelText="degree"
						class="DegreeLabels"
					/>
				</div>
			</example-wrapper>

			We do not put the dots while writing the university names.

			<example-wrapper>
				<style-guide-label 
					v-for="(university, index) in universities"
					:key="index"
					:labelText="university"
					class="UniversityLabels"
				/>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2>Capitalising</h2>
			<p>
				We always capitalise degrees, disciplines and discipline programmes
				in the middle of sentences so that they stand out.
			</p>

			<example-wrapper>
				<p>
					For those pursuing a career in insurance after completing their Master’s
					programme in Finance, there will be opportunities to work with both businesses and
					consumers.
				</p>
			</example-wrapper>

			<p>
				We use the title capitalisation (Chicago Manual of Style) for all the headings we
				use for our website. When in doubt, refer to
				<a 
					href="https://capitalizemytitle.com/" 
					title="Tool for capitalisation" 
					target="_blank">this online tool</a>.
			</p>

			<example-wrapper>
				<p>
					Discover Thousands of Master's Degrees Worldwide!<br>
					Browse by Discipline
				</p>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2 class="CommonWords">Commonly Used Words</h2>
			<style-guide-label
				v-for="(word, index) in wordBank"
				:key="index"
				:labelText="word"
				class="WordLabels"
			/>
		</section>

		<section class="SubItem colblock">
			<h2 class="CommonWords">Common Phrases and Quotes</h2>
			<style-guide-label
				v-for="(phrase, index) in phrases"
				:key="index"
				:labelText="phrase"
				class="WordLabels"
			/>
		</section>
	</article>
</template>

<script>
	import ExampleWrapper from './ExampleWrapper.vue';
	import StyleGuideLabel from './StyleGuideLabel.vue';

	export default {
		name: 'Writing',
		components: {
			StyleGuideLabel,
			ExampleWrapper
		},
		data () {
			return {
				exampleLabel: 'Example',
				britishEnglish:[
					'Programme',
					'Colour',
					'Flavour',
					'Labour',
					'Centre',
					'Dialogue',
					'Enrolment'
				],
				americanEnglish: [
					'Program',
					'Color',
					'Flavor',
					'Labor',
					'Center',
					'Dialog',
					'Enrollment'
				],
				degreeTypes:[
					'B.Sc.',
					'M.Sc.',
					'B.A.',
					'M.A.',
					'LL.B.',
					'LL.M.',
					'B.B.A.',
					'M.Phil.',
					'M.Res.',
					'M.Litt.',
					'M.Ed.',
					'M.Eng.',
					'Ph.D.',
					'M.D.'
				],
				universities:[
					'TU/e',
					'NYU',
					'UCLA',
					'UvA'
				],
				wordBank: [
					'discover',
					'explore',
					'study abroad',
					'adventure',
					'without borders',
					'mobility',
					'life-changing',
					'degree programmes',
					'career',
					'opportunities',
					'achievement',
					'choose',
					'possibilities',
					'international',
					'globally',
					'anywhere',
					'foreign',
					'transparency',
					'results',
					'empowered',
					'ambition',
					'culture',
					'awareness',
					'education',
					'story',
					'hero',
					'epic',
					'confident',
					'learning',
					'field of study',
					'best fit',
					'fun',
					'student life',
					'enthusiastic',
					'commitment',
					'go anywhere',
					'choice',
					'comfort zone',
					'information',
					'universities',
					'study options'
				],
				phrases: [
					'Choose the best fit',
					'discover your possibilities',
					'change your life',
					'explore the world',
					'imagine yourself trying something new',
					'enroll in the best degree programmes',
					'leave your comfort zone',
					'universities are waiting for ambitious students like you',
					'decide what is the best fit for you',
					'see what opportunities are available to you',
					'take a chance',
					'meet new people',
					'ensure transparency',
					'become a leader',
					'go where your ambition is most welcome',
					'your degree programme',
					'discover all study options',
					'don\'t be restricted by border',
					'become the hero of your own story',
					'select a field of study',
					'find all the information you need to make the decision for yourself'
				]
			}
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	#Writing{
		h3{
			margin-bottom: $SpaceL;
		}

		.SubItem{
			max-width: 60rem;
		}

		.ToneOfVoiceShape{
			position: relative;
			width: 16rem;
			height: 16rem;
			margin: 0 auto 3rem;
		}

		.ShapeLabel{
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: $FirstFloor;
			transform: translate(-50%, -50%);
			color: $White;

			@include HeadingSize('H2');
		}

		.ShapeImg{
			z-index: $GroundFloor;
			width: 16rem;
			height: 16rem;
		}

		.ThreeWritingRules{
			margin: $SpaceL 0 0 0;
			padding: 0 0 0 1rem;

			li{
				margin: 0;
			}
		}

		.ToneOfVoiceWrapper{
			flex: 3;
			margin: 1rem 1rem 1rem 0;
		}

		.ButtonsExample{
			> *{
				margin-right: 1rem;
				margin-bottom: 0.5rem;
			}
		}

		.SpellingInformation{
			margin-bottom: 0;
		}

		.SpellingExamples{
			display: inline-block;
			margin-right: 2rem;

			.EnglishList{
				list-style-type: none;
				color: $GreyDD;
				margin: 0;
				padding: 0;

				.Spelling{
					background: $GreyLLL;
					padding: 0.2rem 1rem;
					display: inline-block;
					width: 5rem;

					@include FontSize('SN');
				}

				&.Warning{
					.Spelling{
						background: $RedLL;
					}
				}
			}
		}

		.DegreeLabels, .UniversityLabels, .WordLabels{
			display: inline-block;
			margin: 0 0.5rem 0.5rem 0;

			@include FontSize('SN');
		}

		.CommonWords{
			margin: 0 0 $SpaceL 0;
		}
	}
</style>

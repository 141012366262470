<template>
	<article 
		id="Colour" 
		class="Item col1">
		<!-- The primary palette (orange and blue) -->
		<section class="SubItem colblock">
			<h2 class="ColourModeHeader HeaderHasSelect">Primary Palette</h2>
			<select 
				v-if="$route.name === 'Colours'" 
				class="PageSettingSelect" 
				@input="changeColourMode($event)">
				<option value="web">Web</option>
				<option value="print">Print</option>
			</select>
			<p>
				The orange colour was chosen to evoke a feeling of discovery and achievement,
				while the blue colour symbolises intelligence and idealism. The goal is to
				always place a strong emphasis on the primary palette and never overshadow
				it with the secondary palette.
			</p>

			<div class="VisualBlock">
				<div class="ColourWrapper PrimaryColours">
					<div class="ColourGroup PrimaryColorGroup">
						<div 
							v-for="(colour, index) in orange"
							:key="index"
							class="ColourGroupWrapper"
						>
							<VisualizeColour 
								v-if="index === 0" 
								:colour="colour" 
								:index="index"/>
							<div 
								v-else 
								class="ColourPair">
								<VisualizeColour 
									:colour="colour[0]" 
									:index="1"/>
								<VisualizeColour 
									:colour="colour[1]" 
									:index="2"/>
							</div>
						</div>
					</div>
				</div>

				<div class="ColourWrapper PrimaryColours">
					<div class="ColourGroup PrimaryColorGroup">
						<div 
							v-for="(colour, index) in blue"
							:key="index"
							class="ColourGroupWrapper"
						>
							<VisualizeColour 
								v-if="index === 0" 
								:colour="colour" 
								:index="index"/>
							<div 
								v-else 
								class="ColourPair">
								<VisualizeColour 
									:colour="colour[0]" 
									:index="1"/>
								<VisualizeColour 
									:colour="colour[1]" 
									:index="2"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- The grey palette (6 shades of grey and white) -->
		<section class="SubItem colblock">
			<h2>Grey Palette</h2>
			<p>
				The grey palette helps create a balance between the strong primary colours
				and white areas. The grey colours are mostly used for large backgrounds
				and texts.
			</p>

			<div class="VisualBlock">
				<div 
					v-for="(colour, index) in grey" 
					:key="index"
					class="ColourWrapper GreyColours"
				>
					<div class="ColourGroup">
						<VisualizeColour :colour="colour"/>
					</div>
				</div>
			</div>
		</section>

		<!-- The secondary palette (6 additional colours) -->
		<section class="SubItem colblock">
			<h2>Secondary Palette</h2>
			<p>
				The secondary colours can be used in addition to or alongside our primary
				palette, enabling more flexibility when designing.
			</p>

			<div class="VisualBlock">
				<div 
					v-for="(palette, index) in secondaryPalette"
					:key="index"
					class="ColourWrapper SecondaryColours"
				>
					<div class="ColourGroup">
						<div 
							v-for="(colour, index) in palette"
							:key="index"
							class="ColourGroupWrapper"
						>
							<VisualizeColour 
								v-if="index === 0" 
								:colour="colour" 
								:index="index"/>
							<div 
								v-else 
								class="ColourPair">
								<VisualizeColour 
									:colour="colour[0]" 
									:index="1"/>
								<VisualizeColour 
									:colour="colour[1]" 
									:index="2"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- The tertiary palette (5 additional colours for the client side) -->
		<section 
			v-if="getTarget === 'client'" 
			class="SubItem colblock">
			<h2>Tertiary Palette</h2>
			<p>
				The tertiary colours are used specifically for colour-coding different degrees (e.g. Bachelor, Master)
				of study programmes.
			</p>

			<div class="VisualBlock">
				<div 
					v-for="(palette, index) in tertiaryPaletteClient" 
					:key="index"
					class="ColourWrapper"
				>
					<div class="ColourGroup">
						<div 
							v-for="(colour, index) in palette"
							:key="index"
						>
							<VisualizeColour 
								v-if="index === 0" 
								:colour="colour" 
								:index="index"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	</article>
</template>

<script>
	import VisualizeColour from './VisualizeColour.vue';
	import store from '../../../../store';

	export default {
		name: 'Colour',
		components: { VisualizeColour },
		data () {
			return {
				orange: [
					{
						name: 'BrandOrange',
						rgb: '#F95C39',
						cmyk: '#F26C4A'
					},
					[
						{
							name: 'BrandOrangeD',
							rgb: '#CC4C2F',
							cmyk: '#C8492B'
						},
						{
							name: 'BrandOrangeL',
							rgb: '#FBA693',
							cmyk: '#F9A491'
						}
					]
				],

				blue: [
					{
						name: 'BrandBlue',
						rgb: '#247BA0',
						cmyk: '#2F8EB4'
					},
					[
						{
							name: 'BrandBlueD',
							rgb: '#1B5A75',
							cmyk: '#235A78'
						},
						{
							name: 'BrandBlueL',
							rgb: '#87B7CB',
							cmyk: '#8CB8CA'
						}
					]
				],

				grey: [
					{
						name: 'GreyDD',
						rgb: '#313233'
					},
					{
						name: 'GreyD',
						rgb: '#929699'
					},
					{
						name: 'Grey',
						rgb: '#C4C9CC'
					},
					{
						name: 'GreyL',
						rgb: '#DCE1E5'
					},
					{
						name: 'GreyLL',
						rgb: '#E8ECED'
					},
					{
						name: 'GreyLLL',
						rgb: '#EDF2F4'
					},
					{
						name: 'White',
						rgb: '#FFFFFF'
					},
				],

				secondaryPalette: [
					[
						{
							name: 'Red',
							rgb: '#C75146'
						},
						[
							{
								name: 'RedL',
								rgb: '#EFCFCC'
							},
							{
								name: 'RedLL',
								rgb: '#F9EFEE'
							}
						]
					],
					[
						{
							name: 'Green',
							rgb: '#44AF69'
						},
						[
							{
								name: 'GreenL',
								rgb: '#CCE9D6'
							},
							{
								name: 'GreenLL',
								rgb: '#EEF7F1'
							}
						]
					],
					[
						{
							name: 'Yellow',
							rgb: '#FCAB10'
						},
						[
							{
								name: 'YellowL',
								rgb: '#FEE7C0'
							},
							{
								name: 'YellowLL',
								rgb: '#FEF7E9'
							}
						]
					],
					[
						{
							name: 'Blue',
							rgb: '#39A9DB'
						},
						[
							{
								name: 'BlueL',
								rgb: '#C9E7F5'
							},
							{
								name: 'BlueLL',
								rgb: '#EDF7FB'
							}
						]
					],
					[
						{
							name: 'Purple',
							rgb: '#834555'
						},
						[
							{
								name: 'PurpleL',
								rgb: '#DDCCD0'
							},
							{
								name: 'PurpleLL',
								rgb: '#F3EEEF'
							}
						]
					],
					[
						{
							name: 'Pink',
							rgb: '#EF7674'
						},
						[
							{
								name: 'PinkL',
								rgb: '#FAD9D9'
							},
							{
								name: 'PinkLL',
								rgb: '#FDF2F2'
							}
						]
					]
				],

				tertiaryPaletteClient: [
					[
						{
							name: 'Purple2',
							rgb: '#E253FF'
						}
					],
					[
						{
							name: 'Blue2',
							rgb: '#33E3FF'
						}
					],
					[
						{
							name: 'Green2',
							rgb: '#7ED321'
						}
					],
					[
						{
							name: 'Mint',
							rgb: '#50E3C2'
						}
					],
					[
						{
							name: 'Yellow2',
							rgb: '#F5C923'
						}
					]
				]
			}
		},
		computed: {
			getTarget() {

				return store.getters.target();
			}
		},
		methods:{

			changeColourMode(event){

				const colourModeSelect = event.target;

				if(!colourModeSelect){

					return;
				}

				store.mutations.setColourMode(colourModeSelect.value);
			}
		},
	}
</script>

<style lang="scss">
	@import '../../../../CSS/_variables.scss';

	#Colour{
		font-size: 0;

		.ColourModeHeader{
			display: inline-block;
		}

		.ColourWrapper{
			margin-top: 1rem;
			flex: 1;
			box-sizing: border-box;

			&.PrimaryColours{

				@include Breakpoint(Small, Medium){

					&:last-child{
						padding-top: 2rem;
					}
				}
			}

			&.SecondaryColours{
				flex: 3;
				margin: 1rem;

				/* Only first three need bottom spacing */
				&:nth-child(-n+3){
					margin-bottom: 1rem;
				}
			}
		}

		.ColourGroup{
			display: flex;
			flex-direction: row;

			.ColourGroupWrapper {
				align-self: flex-end;
				box-sizing: border-box;
			}

			.ColourPair{
				display: flex;
				flex-direction: column;
				box-sizing: border-box;

				.Colour{
					display: flex;
					align-items: center;
					margin-top: 0.5rem;
				}
			}

			.PrimaryColourPair{

				@include Breakpoint(Small){
					display: none;
				}
			}
		}

		.PrimaryColorGroup{

			@include Breakpoint(Small){
				flex-wrap: wrap;
			}

			.ColourPair{

				padding-top: 1rem;
			}
		}
	}
</style>
<template>
	<div
		id="App"
		:class="getTarget">
		<div id="AppWrapper">
			<router-view/>
		</div>
	</div>
</template>

<script>
	import store from '../store'
	export default {
		name: 'App',
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
		beforeCreate: function() {
			const targetApp = this.$route.params.target;
			const targetStore = store.getters.target();

			if(targetApp && targetStore !== targetApp){

				store.mutations.setTarget(targetApp);
				store.mutations.setColourMode('web');
			}
		}
	}
</script>

<style lang="scss">
	@import '../CSS/Default.scss';
	@import '../CSS/Typography';
	@import '../CSS/Lists';

	#App {
		height: 100%;
		min-height: 100vh;
		width: 100%;
		margin: 0;
		padding: 0;
	}

	#AppWrapper {
		margin: 0 auto;
	}
</style>
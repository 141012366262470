<template>
	<div class="PackageLinkWrapper">
		<a
			v-if="link_type === 'npm'"
			:href="npmLink"
			class="PackageLink"
			target="_blank"    
		>
			<i class="lnr-launch PackageLinkIcon" /> NPM package
		</a>
		<a
			v-if="link_type === 'github'"
			:href="githubLink"
			class="PackageLink"
			target="_blank"    
		>
			<i class="lnr-launch PackageLinkIcon" /> GitHub repository
		</a>
	</div>    
</template>


<script>
export default {
	name: 'PackageLink',
	props: {
		link_type: {
			type: String,
			required: true
		},
		package_name: {
			type: String,
			required: true
		}
	},
	computed: {
		npmLink() {

			return 'https://www.npmjs.com/package/@studyportals/' + this.package_name;
		},
		githubLink() {

			return 'https://github.com/studyportals/' + this.package_name;
		}
	}
}
</script>

<style lang="scss" scoped>
	.PackageLinkWrapper {
		display: inline-block;
	}
</style>
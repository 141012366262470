<template>
	<div 
		id="BrandIdentity" 
		class="Pane">
		<router-view/>
	</div>
</template>

<script>
    export default {
        name: 'BrandIdentityRouterView'
    }
</script>
<template>
	<article
		id="PrinciplesPrinciples"
		class="Item col1">
		<section class="SubItem colblock">
			<h2>Introduction</h2>

			<p>
				Design Principles are a tool for creating a better, more consistent
				experience for your users. They are high level principles that guide
				the detailed design decisions you make as you're working on a project.
			</p>

			<p>
				Our 4 principles are: <strong>Empathy</strong>, <strong>Clarity &amp; Efficiency</strong>,
				<strong>Consistency</strong> and <strong>Beauty</strong>.
			</p>
			<PrinciplesAnimation/>
		</section>

		<section class="SubItem colblock">
			<h2 class="Principle Empathy">
				<i class="PrincipleIcon lnr-soil"/>
				Empathy
			</h2>
			<dl>
				<dd class="PrincipleRule">Understand user needs and know business objectives</dd>
				<dt class="PrincipleRuleDescription">You can’t deliver the right solution if you don’t understand our user needs and
				their behaviour and the business goals and strategy. Your design decisions are informed by data.</dt>
				<dd class="PrincipleRule">Empathise with our users</dd>
				<dt class="PrincipleRuleDescription">Know where our users are going to: what is their goal, what is the next step
				and how can you help them achieve.</dt>
				<dd class="PrincipleRule">Collaborate with your peers</dd>
				<dt class="PrincipleRuleDescription">Align with stakeholders and exchange ideas with co-workers and colleagues.</dt>
			</dl>
		</section>

		<section class="SubItem colblock">
			<h2 class="Principle ClarityEfficiency">
				<i class="PrincipleIcon lnr-trunk"/>
				Clarity &amp; Efficiency
			</h2>

			<dl>
				<dd class="PrincipleRule">Focus on the relationships between items</dd>
				<dt class="PrincipleRuleDescription">Pay attention to the connection between elements on the page. Create hierarchy.
				Structure the page based on importance.</dt>

				<dd class="PrincipleRule">Create transparency</dd>
				<dt class="PrincipleRuleDescription">Our users should know the effect of each action they take. Help our users
				understand their choices and make your intensions clear.</dt>

				<dd class="PrincipleRule">Avoid unnecessary friction</dd>
				<dt class="PrincipleRuleDescription">Reduce all unnecessary noise. Focus on the message. Keep your design efficient
				by keeping it clear, clean and simple.</dt>

				<dd class="PrincipleRule">The experience is universal</dd>
				<dt class="PrincipleRuleDescription">Make sure the design works on all available platforms, focus on the screen
				sizes most used by our users. No matter where and when, your work needs to be understandable and learnable.</dt>
			</dl>
		</section>

		<section class="SubItem colblock">
			<h2 class="Principle Consistency">
				<i class="PrincipleIcon lnr-leaf"/>
				Consistency
			</h2>
			<dl>
				<dd class="PrincipleRule">Aim for consistent interfaces</dd>
				<dt class="PrincipleRuleDescription">Allow our users to recognise common design patterns. Look for standard controls and
				well-known conventions so that your design behave the way our users expect.</dt>

				<dd class="PrincipleRule">Look for similarity in wording</dd>
				<dt class="PrincipleRuleDescription">Use the same terms or words for similar items to avoid confusion.</dt>

				<dd class="PrincipleRule">Value the brand identity</dd>
				<dt class="PrincipleRuleDescription">Every component in your design incorporates our visual
				language as it plays an essential role in creating a brand aligned and consistent user experience.</dt>
			</dl>
		</section>

		<section class="SubItem colblock">
			<h2 class="Principle Beauty">
				<i class="PrincipleIcon lnr-flower"/>
				Beauty
			</h2>
			<dl>
				<dd class="PrincipleRule">Never a second chance for a first impression</dd>
				<dt class="PrincipleRuleDescription">A design that looks beautiful will create credibility and trust with our users.</dt>

				<dd class="PrincipleRule">Honour the details</dd>
				<dt class="PrincipleRuleDescription">Nothing must be arbitrary or left to chance. Care and accuracy in the design
				show respect to our users.</dt>

				<dd class="PrincipleRule">Delight your user</dd>
				<dt class="PrincipleRuleDescription">Highlight moments that matter. Our users will interact with your design faster
				and with more enthusiasm when it’s pleasant to look at.</dt>
			</dl>
		</section>
	</article>
</template>

<script>
    import PrinciplesAnimation from './PrinciplesAnimation.vue'

	export default {
		name: 'DesignPrinciples',
        components: {
		  PrinciplesAnimation
        },
		data () {
			return {
				msg: ''
			}
		},
	}
</script>

<style lang="scss">
    @import '../../../../CSS/_variables.scss';

    #PrinciplesPrinciples .Principle .PrincipleIcon{
        margin-right: 0.5rem;
        font-size: 2rem;
        line-height: 2.5rem;
        vertical-align: text-bottom;
    }

    #PrinciplesPrinciples .PrincipleRule{
        display: block;
        color: $GreyDD;
        margin-bottom: 0.5rem;
        @include HeadingSize('H5');
    }

    #PrinciplesPrinciples .PrincipleRuleDescription{
        color: $GreyD;
        margin-bottom: 1.5rem;
        max-width: 30rem;
    }
</style>

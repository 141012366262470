<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedPrinciples/>
	</div>
</template>

<script>
	import SharedPrinciples from '../components/Shared/Components/Principles/Principles.vue';
	import store from '../store';

	export default {
		name: 'PrinciplesPane',
		components: { SharedPrinciples },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>

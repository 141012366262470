import state from "./state";
const getters = {
    target: () => {
        return state.target;
    },
    colourMode: () => {
        return state.colourMode;
    },
    sidebarClosed: () => {
        return state.sidebarClosed;
    }
};
export default getters;

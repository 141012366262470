<template>
	<section class="SubItem colblock">
		<h2>Introduction</h2>
		<p>
			We use cover photos in order to get the students interested, excited and motivated to take their next step
			in education in a visual way. In order to ensure consistency among the cover photos in terms of quality,
			size, resizing behavior and content that goes on top of them, we came up with a set of rules.
		</p>
	</section>
</template>

<script>
    export default {
        name: "Introduction"
    }
</script>

<template>
	<article 
		id="Imagery" 
		class="Item col1">
		<section class="SubItem colblock">
			<!-- The top part should be hidden for clients -->
			<template v-if="getTarget === 'student'">
				<h2>Hero Images</h2>
				<p>
					Hero characters represent students who are part of a multicultural
					community, sharing a belief in a better, more connected world.
					They are usually placed in the centre of the image; this means
					that they are at the core of our focus. We use these characters
					to inspire students to be curious, to investigate, to explore,
					and to eventually find their best education option abroad using
					Studyportals.
				</p>
				<figure 
					class="VisualBlock HeroImages" 
					role="group">
					<figure 
						v-for="(hero, index) in heroes" 
						:key="index"
						class="HeroImg"
					>
						<img 
							:class="hero.className" 
							:alt="hero.alt">
					</figure>
					<figcaption class="ImageCaption">Hero images on the portals</figcaption>
				</figure>
			</template>

			<section class="SubItem">
				<h2>General Guidelines</h2>
				<span>At Studyportals, we use images that are featured by:</span>
				<ul class="HeroList">
					<li>documental or editorial record</li>
					<li>non-exaggerated acting</li>
					<li>real life situations</li>
				</ul>
				<example-wrapper>
					<figure>
						<img 
							class="Dos" 
							src="//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDos.jpg?q=60&auto=format">
					</figure>
				</example-wrapper>
			</section>
			<section class="SubItem">
				<span>We DON’T use images that are featured by:</span>
				<ul class="HeroList">
					<li>visual metaphors or unreal compositions</li>
					<li>unreal lighting or color overblowing the image</li>
					<li>exaggerated acting</li>
				</ul>
				<example-wrapper 
					:type="'warning'" 
					labelText="Don't" 
					labelIcon="lnr-warning" 
					class="SpellingExamples">
					<figure>
						<img 
							class="Donts" 
							src="//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDonts.jpg?q=60&auto=format&w=10&w=1280">
					</figure>
				</example-wrapper>
			</section>
		</section>
	</article>

</template>

<script>
	import ExampleWrapper from './ExampleWrapper.vue';
	import StyleGuideLabel from './StyleGuideLabel.vue';
	import store from '../../../store'

	export default {
		name: 'Imagery',
		components: {
			StyleGuideLabel,
			ExampleWrapper
		},
		data () {
			return {
				heroes:[
					{
						className:'BachelorHero',
						alt:'Hero Image Bachelorsportal',
					},
					{
						className:'MasterHero',
						alt:'Hero Image Mastersportal',
					},
					{
						className:'PhDHero',
						alt:'Hero Image PhDportal',
					},
					{
						className:'ShortHero',
						alt:'Hero Image ShortCoursesportal',
					},
					{
						className:'PrepHero',
						alt:'Hero Image PreparationCoursesportal',
					},
					{
						className:'DistanceHero',
						alt:'Hero Image DistanceLearningportal',
					}
				]
			}
		},
		computed: {
			getTarget() {

				return store.getters.target();
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	#Imagery{

		.HeroList{
			margin: 0.5rem 0 0 0;
			padding-left: 1.1rem;

		}


		.HeroImages{

			.HeroImg{
				margin-bottom: 3rem;

				&:nth-last-child(2){
					margin-bottom: 0;
				}
			}
			.BachelorHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/BAHeroA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/BAHeroA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.MasterHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/MAHeroA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/MAHeroA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.PhDHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/PhDHeroA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/PhDHeroA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.ShortHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroShortA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroShortA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.PrepHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroPrepA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroPrepA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.DistanceHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/DLHeroB.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/DLHeroB.png?q=60&auto=format&w=10&w=1280");
				}
			}
		}

		.Dos{
			content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDos.jpg?q=60&auto=format&w=768");

			@include Breakpoint(ExtraLarge, Large){
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDos.jpg?q=60&auto=format&w=10&w=1280");
			}
		}

		.Donts{
			content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDonts.jpg?q=60&auto=format&w=768");

			@include Breakpoint(ExtraLarge, Large){
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDonts.jpg?q=60&auto=format&w=10&w=1280");
			}
		}
	}

</style>

<template>
	<div 
		:class="className" 
		class="ExampleLogo">
		<img 
			:src="logo.src" 
			alt="logo.name">
	</div>
</template>

<script>
	export default {
		name: 'VisualizeLogo',
		props: {
			logo: {
				type: Object,
				required: true
			},
            className: {
				type: String,
				default: ''
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../../../CSS/_variables.scss';

	.ExampleLogo{

		flex: 1 1 auto;
		position: relative;
		min-width: 12rem;

		&.Grey{
			background-color: $GreyLL;
		}
		&.Orange{
			background-color: $BrandOrange;
		}
		&.Blue{
			background-color: $BrandBlue;
		}
		&.LightBg{
			background-image: url("http://monet-prtl-co.imgix.net/StyleGuide/Logos/ApplicationLightBackground.png");
		}
		&.DarkBg{
			background-image: url("http://monet-prtl-co.imgix.net/StyleGuide/Logos/ApplicationDarkBackground.png");
		}
	}
</style>

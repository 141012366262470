<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedImagery/>
	</div>
</template>

<script>
	import SharedImagery from '../components/Shared/Components/Imagery.vue';
	import store from '../store';

	export default {
		name: 'ImageryPane',
		components: { SharedImagery },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
<template>
	<div id="galactus">
		<SharedSpacing/>
	</div>
</template>

<script>
	import SharedSpacing from '../components/Shared/Components/Spacing.vue';
	import store from '../store';

	export default {
		name: 'ComponentsSpacing',
		components: { SharedSpacing },
		computed: {
			getTarget () {
				return store.getters.target();
			}
		},
	}
</script>
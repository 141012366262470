<template>
	<article
		id="Navigation"
		class="Item col1">

		<section class="SubItem colblock">
			<h2>Introduction</h2>
			<p v-if="getTarget === 'student'">
				At Studyportals, we believe giving detailed information about study options is
				a key to help students make better choices. Navigation elements make it easier
				for the students to easily scan and switch between vast amount of information
				about study programmes.
			</p>
			<p v-else>
				At Studyportals, our clients often need to manage a wide range of their programme
				profiles and campaigns. Navigation elements play an important role in providing
				an easy and efficient access to their programmes and reports.
			</p>
		</section>

		<section class="SubItem colblock">
			<h2>Horizontal Navigation</h2>
			<p>
				Horizontal navigation helps categorising the content in a manageable number of items.
				The navigation tabs are placed on the background of the page while the content has
				white background for readability.
			</p>

			<div id="NavigationExample">
				<nav id="ContentSwitcher">
					<a
						:class="{ Active: tabs['1'] }"
						@click="setTab(1)"
					>
						Item 1
					</a>
					<a
						:class="{ Active: tabs['2'] }"
						@click="setTab(2)"
					>
						Item 2
					</a>
					<a
						:class="{ Active: tabs['3'] }"
						@click="setTab(3)"
					>
						Item 3
					</a>
					<a
						:class="{ Active: tabs['4'] }"
						@click="setTab(4)"
					>
						Item 4
					</a>
					<a
						:class="{ Active: tabs['5'] }"
						@click="setTab(5)"
					>
						Item 5
					</a>
				</nav>
				<div id="ContentWrapper">

					<a
						class="DrawerToggle"
						@click="toggleTab(1)"
					>
						Item 1 <i :class="[tabs['1'] ? 'lnr-chevron-up' : 'lnr-chevron-down', 'FoldButton']"/>
					</a>
					<div
						:class="{ Active: tabs['1'] }"
					>
						<h2>Title under Item 1</h2>
						<p>This content is related to Item 1.</p>
					</div>

					<a
						class="DrawerToggle"
						@click="toggleTab(2)"
					>
						Item 2 <i :class="[tabs['2'] ? 'lnr-chevron-up' : 'lnr-chevron-down', 'FoldButton']"/>
					</a>
					<div
						:class="{ Active: tabs['2'] }"
					>
						<h2>Title under Item 2</h2>
						<p>This content is related to Item 2.</p>
					</div>

					<a
						class="DrawerToggle"
						@click="toggleTab(3)"
					>
						Item 3 <i :class="[tabs['3'] ? 'lnr-chevron-up' : 'lnr-chevron-down', 'FoldButton']"/>
					</a>
					<div
						:class="{ Active: tabs['3'] }"
					>
						<h2>Title under Item 3</h2>
						<p>This content is related to Item 3.</p>
					</div>

					<a
						class="DrawerToggle"
						@click="toggleTab(4)"
					>
						Item 4 <i :class="[tabs['4'] ? 'lnr-chevron-up' : 'lnr-chevron-down', 'FoldButton']"/>
					</a>
					<div
						:class="{ Active: tabs['4'] }"
					>
						<h2>Title under Item 4</h2>
						<p>This content is related to Item 4.</p>
					</div>

					<a
						class="DrawerToggle"
						@click="toggleTab(5)"
					>
						Item 5 <i :class="[tabs['5'] ? 'lnr-chevron-up' : 'lnr-chevron-down', 'FoldButton']"/>
					</a>
					<div
						:class="{ Active: tabs['5'] }"
					>
						<h2>Title under Item 5</h2>
						<p>This content is related to Item 5.</p>
					</div>
				</div>
			</div>

			<p
				v-if="getTarget === 'student'"
			>
				Sticky Behaviour:<br>
				Horizontal navigation can be configured so that the navigation items stick to the header
				once the user scrolls past them
			</p>

			<p
				v-if="getTarget === 'student'"
			>
				Mobile behaviour:<br>
				For S and M breakpoints, the navigation tabs are displayed as individual cards one after
				another because we believe this makes the content easier to scan by not hiding any of the
				items and easier to click on for mobile devices. We preserve the same order for all
				breakpoints (from top to bottom for S and M, from left to right for L and XL breakpoints).

			</p>

			<figure
				v-if="getTarget === 'student'"
				class="VisualBlock">
				<img
					src="http://monet-prtl-co.imgix.net/StyleGuide/Navigation/mobile behaviour.png"
					alt="Desktop and mobile variations"
				>
				<figcaption class="ImageCaption">Desktop and mobile variations</figcaption>
			</figure>
		</section>

		<section class="SubItem colblock">
			<h2>General Guidelines</h2>
			<ul class="Guidelines">
				<li>
					For findability, we make sure horizontal navigation is visible on the page
					load for the most used desktop screen size (1366x768).
				</li>
				<li>
					We prioritise the sections so that they can be presented from left to right
					for L and XL breakpoints, and from top to bottom for S and M breakpoints.
				</li>
				<li>
					We use sentence capitalisation for all the navigation bar items.
				</li>
				<li>
					If the navigation bar item has more than 2 words, rethink it.
				</li>
				<li>
					If the number of the navigation bar items exceeds 5, rethink it.
				</li>
			</ul>
		</section>

	</article>
</template>

<script>
	import store from '../../../store'
	export default {
		name: 'Navigation',
		data() {
			return {
				tabs: {
					'1': true,
					'2': false,
					'3': false,
					'4': false,
					'5': false,
				}
			}
		},
		computed: {
			getTarget() {

				return store.getters.target();
			}
		},
		mounted() {

			// Create window resize listener to see when menu should collapse
			window.addEventListener('resize', () => {

				this.responsiveHandler();
			});
		},
		methods: {
			setTab(tab) {

				for (var key in this.tabs) {
					this.tabs[key] = false;
				}

				this.tabs[tab] = true;
			},
			toggleTab(tab) {

				if(this.tabs[tab]) {
					this.tabs[tab] = false;
					return;
				}

				this.tabs[tab] = true;
			},
			responsiveHandler: function(){

				if(window.innerWidth > 767) {

					var activeTabs = 0;

					for (var key in this.tabs) {

						if(this.tabs[key]) {
							activeTabs++;
						}

						if(activeTabs > 1) {
							this.tabs[key] = false;
						}
					}

					if(activeTabs === 0) {
						this.setTab(1);
					}
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../../CSS/_variables.scss';

	#NavigationExample {
		max-width: 50rem;
		padding: $SpaceS;
		margin: $SpaceN 0;
		background: $GreyLL;
	}

	#ContentSwitcher {
		display: none;

		@include Breakpoint(Large, ExtraLarge) {
			display: block;
			margin: 0 0 1rem;
		}

		a {
			font-size: 1.5rem;
			color: $GreyD;
			cursor: pointer;
			display: inline-block;
			height: 30px;
			margin: 0 $SpaceS;
			background: none;
			@include FontSize('N');

			&.Active {
				color: $BrandBlue;
				border-bottom: 0.25rem $BrandBlue solid;
			}

			&:hover {
				text-decoration: none;
			}

			&:not(.Active):hover {
				border-bottom: 0.25rem $GreyL solid;
			}
		}
	}

	#ContentWrapper {

		.DrawerToggle {
			display: block;
			height: 60px;
			line-height: 60px !important;
			padding: 0 $SpaceS;
			margin-top: 1rem;
			@include HeadingSize('H3');
			color: $GreyDD;
			background: $White;
			cursor: pointer;

			@include Breakpoint(Large, ExtraLarge) {
				display: none;
			}

			&:first-child {
				margin-top: 0;
			}

			&:hover {
				text-decoration: none;
			}

			.FoldButton {
				float: right;
				position: relative;
				top: 50%;
				transform: translateY(-50%);

				@include Breakpoint(Large, ExtraLarge) {
					display: none;
				}
			}
		}

		div {
			display: none;
			padding: $SpaceS $SpaceS $SpaceN;
			background: $White;

			&.Active {
				display: block;
			}

			h2 {
				@include HeadingSize('H2');
			}
		}
	}

	.VisualBlock {
		display: flex;
		align-items: center;
		flex-flow: column nowrap;
		max-width: 50rem;
	}

	.Guidelines{
		margin: $SpaceL 0 0 0;
	}
</style>
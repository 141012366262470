<template>
	<article
		id="UserJourney"
		class="Item col1">
		<section
			v-if="getTarget === 'student'"
			class="SubItem colblock">
			<h2>Student Journey</h2>

			<p>
				The student journey gives us more context and clarity on activities every department
				at Studyportals work on. By mapping the user journey, we can get insight into the steps
				a student takes while achieving their goals. Using these insights, you’ll be able to see
				where we’re doing well and where there is room for improvement, to provide better
				service to students:
			</p>

			<ul class="UserJourneyList">
				<li class="UserJourneyListItem">What does the student want, do, think and feel at every stage of the journey</li>
				<li class="UserJourneyListItem">When and how do the students on this journey interact with
				our portals or external sources</li>
				<li class="UserJourneyListItem">What is the student’s goal at each stage and how do we help them</li>
			</ul>


			<p>
				If you have any questions or comments, feel free to reach out to us at
				<a href="mailto:userjourney@studyportals.com">userjourney@studyportals.com</a>.
			</p>
		</section>

		<section
			v-if="getTarget === 'client'"
			class="SubItem colblock">
			<h2>Client Journey</h2>

			<p>
				Contents coming soon!
			</p>
		</section>
	</article>
</template>

<script>
	import store from '../../../store';
	export default {
		name: 'UserJourney',
		data () {
			return {
				msg: ''
			}
		},
		computed:{
			getTarget(){

				return store.getters.target();
			}
		}
	}
</script>

<style lang="scss">
    @import '../../../CSS/_variables.scss';

    #UserJourney .UserJourneyListItem{
        max-width: 30rem;
    }
</style>


<template>
	<article
		id="DOT"
		class="Item col1">
		<section class="SubItem colblock">
			<h2>Who Are We?</h2>
			<p>
				Hey there! Happy to have you reading this section of the style guide!
				Take this as a virtual handshake from the Studyportals Design Operations Team (DOT) –
				a number of designers and front-end developers dedicated on crafting an effortless
				student experience. We have two main goals. Firstly, we want to increase the trust
				of students using our services by giving them a consistent and aesthetic experience
				on our websites. Secondly, we aim to make our internal design and development process
				more efficient by creating reusable solutions.
			</p>
			<p>
				At DOT, we take change of diverse initiatives: we connect scrum teams, design and develop
				the Studyportals styleguide, and collaborate with the Marketing team to align our products
				with our brand identity. And in the process, we work with three imperatives in mind:
			</p>
			<ul>
				<li>Communicate often</li>
				<li>Be flexible</li>
				<li>Deliver tangible results</li>
			</ul>
			<p>
				Reach out to us for feedback, questions, suggestions or just a casual chat! Send us an e-mail at dot@studyportals.com
			</p>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'DOT'
	}
</script>

<style lang="scss">
</style>
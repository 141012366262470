<template>
	<div class="DownloadLogos">
		<div class="DownloadLogosWrapper">
			<slot/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@import '../../../../CSS/_variables.scss';

	.DownloadLogos{
		.DownloadLogosWrapper{
			display: flex;
			flex-wrap: wrap;

			.DownloadLogo{
				margin: 2rem 1rem 0 0;
			}
		}
	}
</style>
<template>
	<div 
		:data-tooltip-text="tooltipText" 
		class="InfoIcon">
		<slot/>
	</div>
</template>


<script>
import Tooltip from './Tooltip.js';

export default {
	name: 'Tooltip',
	props: {
		tooltipText: {
			type: String,
			required: true
		}
	},
	mounted () {

		if(this.$route.meta.earlyPhase) {

			document.dispatchEvent(new CustomEvent("render_complete"));
		}

		new Tooltip('App');
	},
	updated(){

		new Tooltip('App');
	},
}
</script>

<style lang="scss">
	@import 'Tooltip.scss';
</style>

<template>
	<div 
		id="galactus" 
		class="Pane">
		<SharedIcons/>
	</div>
</template>

<script>
    import SharedIcons from '../components/Shared/Components/Icons.vue';
    import store from '../store';

    export default {
        name: 'VisualIcons',
        components: { SharedIcons },
        computed: {
            getTarget () {
                return store.getters.target();
            }
        },
    }
</script>
<template>
	<div id="galactus">
		<SharedColour/>
	</div>
</template>

<script>
    import SharedColour from '../components/Shared/Components/Colour/Colour.vue';
    import store from '../store';   
    export default {
        name: 'VisualColour',
        components: { SharedColour },
        computed: {
            getTarget () {
                return store.getters.target();
            }
        },
    }
</script>
<template>
	<section class="SubItem colblock">
		<h2>No Photo Available</h2>
		<p>
			If there is no cover photo available for a certain page, either use a placeholder or plain background with
			GreyLLL. For the breakpoints S and M, get rid of the cover photo area if you are using a plain background
			with GreyLLL.
		</p>
	</section>
</template>

<script>
    export default {
        name: "NoPhotoAvailable"
    }
</script>

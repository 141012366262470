<template>
	<article
		id="Spacing"
		class="Item col1">
		<section
			class="SubItem colblock">
			<h2>Introduction</h2>
			<p>
				Spacing is an important yet often over-looked visual element.
				Applying the right spacing can help create visual balance,
				hierarchy and consistency between elements on the page.
			</p>
			<div class="PackageLinks">
				<p>
					Access your component via:
				</p>
				<PackageLink
					:link_type="'npm'"
					:package_name="package_name"
				/>
				<PackageLink
					:link_type="'github'"
					:package_name="package_name"
				/>
			</div>
		</section>
		<section
			class="SubItem colblock">
			<h2>Spacing blocks and usage</h2>
			<p>
				Using the 8 pixel grid as reference, we define spacing blocks in multiples of 8px.
				There are 9 different spacing blocks which can be used for page layout as
				well as within components on the page, e.g. applied to margins and paddings.
			</p>
			<div class="VisualBlock">
				<img
					class="SpacingImage"
					alt="Layout and component spacing"
					src="//monet-prtl-co.imgix.net/StyleGuide/Components/Spacing/spacing-blocks-rems.svg">
			</div>

			<div class="VisualBlock">
				<img
					class="SpacingImage"
					alt="Layout and component spacing"
					src="//monet-prtl-co.imgix.net/StyleGuide/Components/Spacing/layout-and-component-spacing.svg">
				<span class="ExampleIntro">Layout and component spacing</span>
			</div>


			<example-wrapper
				:type="'example'"
				labelText="Example">
				<div class="VisualBlock">
					<img
						class="SpacingImage"
						alt="Layout and component spacing"
						src="//monet-prtl-co.imgix.net/StyleGuide/Components/Spacing/spacing-example.png">
				</div>
			</example-wrapper>
		</section>
	</article>
</template>

<script>
	import ExampleWrapper from './ExampleWrapper.vue';
	import PackageLink from '../Patterns/PackageLink/PackageLink';
	import store from '../../../store';

	export default{
		name: 'Spacing',
		components:{
			ExampleWrapper,
			PackageLink
		},
		data() {
			return {
				package_name: 'styles-abstracts'
			}
		},
		computed:{
			getTarget(){

				return store.getters.target();
			}
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';

	#Spacing{
		.VisualBlock{
			margin: 3rem 0 0;
		}
		.SpacingImage{
			width: 100%;
		}
		.PackageLinks {
			margin-top: 2rem;

			.PackageLinkWrapper {
				margin-right: 1rem;
			}
		}
		.ExampleIntro{
			width: 100%;
			text-align: center;
			margin: 1rem;
		}
	}
</style>

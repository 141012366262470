<template>
	<article 
		id="Navigation" 
		class="Item col1">

		<section class="SubItem colblock">
			<h2>Introduction</h2>
			<p>
				Form elements can be used to the input from users, they appear often in forms and dialogues.
				When using form elements in your UI, it's good to consider the type of input needed, error handling
				and responsive behaviour.
			</p>
			<div class="PackageLinks">
				<p>
					Access form elements via:
				</p>
				<PackageLink 
					:link_type="'npm'"
					:package_name="package_name"
				/>
				<PackageLink 
					:link_type="'github'"
					:package_name="package_name"
				/>
			</div>
		</section>

		<section class="SubItem colblock">
			<h2>Single-line input</h2>
			<p>
				Single-line input fields accept free-text input from users. However,
				when the length of the input is longer than the length of the elements
				itself, the input will be truncated thus cannot be displayed
				completely.
			</p>

			<form
				id="StudyportalsForm"
				class="FgForm" 
				name="ExampleForm"
				novalidate
				autocomplete="off"
			>
				<div class="FgElementWrapper">
					<input
						id="ExampleId"
						name="ExampleName"
						class="FgInput js-Input"
						type="text"
					>

					<label 
						class="FgLabel"
						for="ExampleId"
					>
						Example
					</label>
				</div>
			</form>
			
			<p>
				Border-radius: <strong>3px</strong>, height: <strong>40px</strong>, 
				font-size: <strong>15px</strong>, font-weight: <strong>regular</strong>, 
				padding: <strong>12px</strong>
			</p>
			
			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">Available states:</span>
				</div>
				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/default.svg"
							alt="Empty"
						>
						<span class="State">Empty</span>
					</div>

					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/focused.svg"
							alt="Focused"
						>
						<span class="State">Focused</span>
					</div>

					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/out-of-focus.svg"
							alt="Out of focus"
						>
						<span class="State">Out of focus</span>
					</div>

					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/disabled.svg"
							alt="Disabled"
						>
						<span class="State">Disabled</span>
					</div>
				</div>
			</div>
				
			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">
						Error handling <span class="SecondaryText">(NOT implemented currently)</span>:
					</span>
				</div>
				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/error-withinput.svg"
							alt="Error state with input"
						>
					</div>

					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/error-empty.svg"
							alt="Error state without input"
						>
					</div>
				</div>
			</div>

			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">
						With helper message <span class="SecondaryText">(NOT implemented currently)</span>:
					</span>
				</div>
				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/default-helper.svg"
							alt="Default helper message"
						>
					</div>

					<div class="ComponentWrapper">
						<img
							src="http://monet-prtl-co.imgix.net/StyleGuide/FormElements/SingleLineInput/focused-helper.svg"
							alt="Helper message on focused input"
						>
					</div>
				</div>
			</div>

		</section>

		<section class="SubItem colblock">
			<CodeSnippets 
				:package_name="package_name"
			/>
		</section>
		
	</article>
</template>

<script>
	import FormGenerator from '@studyportals/form-generator';
	import PackageLink from '../Patterns/PackageLink/PackageLink';
	import CodeSnippets from '../Patterns/CodeSnippets/CodeSnippets';

	export default {
		name: 'FormElements',
		components: {
			PackageLink,
			CodeSnippets
		},
		data() {
			return {
				package_name: 'form-generator'
			}
		},
		mounted() {

			const form = document.getElementById('StudyportalsForm');

			if(!form) return;
			
			const fgForm = new FormGenerator(form);
			fgForm.generate();
		}
	}
</script>

<style lang="scss" scoped>
	@import './node_modules/@studyportals/form-generator/src/sass/FormGenerator';

	.PackageLinks {
		margin-top: 2rem;

		.PackageLinkWrapper {
			margin-right: 1rem;
		}
	}

	.FgForm {
		margin-top: 3rem;

		#ExampleId {
			width: 320px;
		}
	}
</style>

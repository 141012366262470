<template>
	<article
		id="Writing"
		class="Item col1">
		<section class="SubItem colblock">
			<h2>Introduction</h2>

			<p>We give buttons different looks (e.g. colour, size) to introduce
			a hierarchy on user actions on the website. The user actions on
			a page should have very clear focus and not in conflict with each
			other. Based on the primacy of the actions, we defined <b>champion
			button</b>, <b>driver button</b>, <b>navigator button</b> and <b>helper button</b>.</p>
		</section>

		<section class="SubItem colblock">
			<h2>Champion Button</h2>

			<p>Champion button is the loudest button on the page. It is constantly
			shouting “look at me” and motivate users to take the most important
			action on the page.</p>

			<div class="VisualBlock DisplayBlock AlignLeft">
				<button class="ChampionButton">Visit programme website</button>

				<p class="Details">Border-radius: <b>3px</b>, height: <b>56px</b>,
				font-size: <b>17px</b>, font-weight: <b>semibold</b>,
				padding: <b>24px</b></p>
			</div>

			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">Available states:</span>
				</div>

				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<button class="ChampionButton NoHover">Visit programme website</button>
						<span class="State">Normal</span>
					</div>

					<div class="ComponentWrapper">
						<button class="ChampionButton Hover">Visit programme website</button>
						<span class="State ">Hover</span>
					</div>
				</div>
			</div>
		</section>

		<section class="SubItem colblock">
			<h2>Driver Button</h2>

			<p>Driver button shares a similar misson as the champion button,
			except that it achieves it in a much milder manner. Driver button
			draws attention to certain content on the page and inspires the
			users to take actions.</p>

			<div class="VisualBlock DisplayBlock AlignLeft">
				<h3 class="buttonHeader">Orange Driver</h3>
				<button class="DriverButton">Log in</button>

				<p class="Details">Border-radius: <b>3px</b>, border: <b>2px</b>,
				height (including border): <b>40px</b>, font-size: <b>15px</b>, font-weight:
				<b>semibold</b>, padding: <b>16px</b></p>
			</div>

			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">Available states:</span>
				</div>

				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<button class="DriverButton NoHover" >Log in</button>
						<span class="State">Normal</span>
					</div>

					<div class="ComponentWrapper">
						<button class="DriverButton Hover" >Log in</button>
						<span class="State">Hover</span>
					</div>
				</div>
			</div>

			<div class="VisualBlock DisplayBlock AlignLeft">
				<h3 class="buttonHeader">Blue Driver</h3>
				<button class="DriverButton Blue" >Log in</button>

				<p class="Details">Border-radius: <b>3px</b>, border: <b>2px</b>,
				height (including border): <b>40px</b>, font-size: <b>15px</b>, font-weight:
				<b>semibold</b>, padding: <b>16px</b></p>
			</div>

			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">Available states:</span>
				</div>

				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<button class="DriverButton Blue NoHover" >Log in</button>
						<span class="State">Normal</span>
					</div>

					<div class="ComponentWrapper">
						<button class="DriverButton Blue Hover" >Log in</button>
						<span class="State">Hover</span>
					</div>
				</div>
			</div>

			<example-wrapper
				:type="'variations'"
				labelText="Variations"
				class="ButtonVariation">
				<p>For a dark-color background, we introduced a white version
				of the driver button.</p>

				<div class="Example">
					<div class="Wrapper">
						<div class="ComponentWrapper DarkBackground">
							<button 
								v-if="getTarget === 'student'" 
								class="DriverButton OnDarkBackground NoHover">Take the test</button>
							<button
								v-else
								class="DriverButton OnDarkBackground NoHover">Log out</button>
							<span class="State">Normal</span>
						</div>

						<div class="ComponentWrapper DarkBackground">
							<button 
								v-if="getTarget === 'student'" 
								class="DriverButton OnDarkBackground Hover">Take the test</button>
							<button
								v-else
								class="DriverButton OnDarkBackground Hover">Log out</button>
							<span class="State">Hover</span>
						</div>
					</div>
				</div>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2>Navigator Button</h2>

			<p>Navigator Button guides users with the actions that are not the most
			important. with its neutral look, it can be used in different contexts.</p>

			<div class="VisualBlock DisplayBlock AlignLeft">

				<button class="NavigatorButton">Read more</button>

				<p class="Details">Border-radius: <b>3px</b>, border: <b>2px</b>,
				height: <b>40px</b>, font-size: <b>15px</b>, font-weight:
				<b>semibold</b>, padding: <b>16px</b></p>
			</div>

			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">Available states:</span>
				</div>

				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<button class="NavigatorButton NoHover">Read more</button>
						<span class="State">Normal</span>
					</div>

					<div class="ComponentWrapper">
						<button class="NavigatorButton Hover">Read more</button>
						<span class="State">Hover</span>
					</div>
				</div>
			</div>
		</section>

		<section class="SubItem colblock">
			<h2>Helper Button</h2>

			<p>Helper button is modest but aims to be helpful. It provides users
			in-context support without distracting them from focusing on the
			main content. For touch screens, we specified a touch area to give
			the users’ fingers an easier access.</p>

			<div class="VisualBlock DisplayBlock AlignLeft">
				<button class="HelperButton">
					<span>Read more</span>
				</button>

				<p class="Details">Font-size: <b>15px</b>, font-weight: <b>semibold</b></p>
			</div>

			<div class="VisualBlock Vertical">
				<div class="Wrapper">
					<span class="States">Available states:</span>
				</div>

				<div class="Wrapper ComponentGroup">
					<div class="ComponentWrapper">
						<button class="HelperButton NoHover">
							<span>Read more</span>
						</button>
						<span class="State">Normal</span>
					</div>

					<div class="ComponentWrapper">
						<button class="HelperButton Hover">
							<span>Read more</span>
						</button>
						<span class="State ">Hover</span>
					</div>
				</div>
			</div>

			<div class="VisualBlock Vertical AlignLeft">

				<span class="States">For touch screens:</span>

				<div class="TouchButtonWrapper">
					<button class="HelperButton ShowTouchSurface">
						<span>Read more</span>
					</button>
					<div class="Pointer"/>
					<p class="Details">Touch area, height: <b>40px</b>, minimum padding: <b>16px</b></p>
				</div>
			</div>

			<example-wrapper
				:type="'variations'"
				labelText="Variations"
				class="ButtonVariation">
				<p>We introduced a grey version of the helper button for actions
				that have even lower primacy.</p>

				<div class="Example">
					<div class="Wrapper ComponentGroup">
						<div class="ComponentWrapper">
							<button class="HelperButton LowPriority NoHover">
								<span v-if="getTarget === 'student'">Improve page content</span>
								<span v-else>Cancel</span>
							</button>
							<span class="State">Normal</span>
						</div>

						<div class="ComponentWrapper">
							<button class="HelperButton LowPriority Hover">
								<span v-if="getTarget === 'student'">Improve page content</span>
								<span v-else>Cancel</span>
							</button>
							<span class="State">Hover</span>
						</div>
					</div>
				</div>
			</example-wrapper>
		</section>

		<section class="SubItem colblock">
			<h2>Icon Button</h2>

			<p class="IconButtonInfo">Icon button only uses an icon as a label. Icon button informs users
			about an action that is usually well-recognisable across the product,
			such as searching, saving, editing etc.</p>

			<div class="VisualBlock Inline Vertical IconButtons">

				<div class="Wrapper">
					<div class="IconButtonGroups">
						<button class="IconButton Search NoHover">
							<i class="lnr-magnifier SearchIcon"/>
						</button>

						<button class="IconButton Search Hover">
							<i class="lnr-magnifier SearchIcon"/>
						</button>
					</div>

					<div class="IconButtonGroups">
						<button class="IconButton">
							<i class="lnr-heart-full HeartIcon NoHover"/>
						</button>

						<button class="IconButton">
							<i class="lnr-heart-full HeartIcon Hover"/>
						</button>
					</div>

					<div class="IconButtonGroups">
						<button class="IconButton">
							<i class="lnr-pencil PencilIcon NoHover"/>
						</button>

						<button class="IconButton">
							<i class="lnr-pencil PencilIcon Hover"/>
						</button>
					</div>
				</div>

				<div class="Wrapper">
					<span class="Details">Normal and hover states of icon buttons</span>
				</div>
			</div>
		</section>

		<section class="SubItem colblock">
			<h2>General Guidelines for Buttons</h2>

			<ul class="ButtonsGuidelines">
				<li>We always centre the labels for all the buttons.</li>
				<li>You can always use Icons for buttons, a minimum of 16px spacing between an icon and the texts is suggested.</li>
				<li>We do not suggest to use hover effects for buttons on touch screens</li>
			</ul>
		</section>

		<section class="SubItem colblock">
			<h2>Links</h2>

			<p>A link is a clickable piece of texts embedded in the content.
			Links inspire users to click on them for further information.</p>

			<example-wrapper
				:type="'example'"
				labelText="Example">

				<div class="Wrapper Vertical ExampleLinks">
					<span>This is a <a
						href="#"
						class="NonVisitedLink">link</a> in a sentence.</span>

					<span>This is a <a
						href="#"
						class="VisitedLink">visited link</a> in a sentence.</span>
				</div>

			</example-wrapper>
		</section>
	</article>
</template>

<script>
	import store from '../../../store';
	import ExampleWrapper  from './ExampleWrapper.vue'

	export default{
		name: 'Buttons',
		components:{ ExampleWrapper },
		data (){
			return{
				msg: 'hello'
			}
		},
		computed:{
			getTarget(){

				return store.getters.target();
			}
		},
	}
</script>

<style lang="scss">
	@import '../../../CSS/_variables.scss';
	@import '@studyportals/styles-components/components/Button.scss';

	button:hover{
		cursor: pointer;
	}

	.AlignLeft{
		align-items: left;
	}

	#Writing{
		.buttonHeader{
			margin-bottom: 3rem;
		}
	}
	.ChampionButton{

		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		&.NoHover:hover{
			background: $BrandOrange;
		}
	}

	.DriverButton{

		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		&.NoHover:hover{
			color: $White;
			background-color: $BrandOrange;
			border-color: $BrandOrange;
		}

		&.Blue{

			&.NoHover:hover{
				color: white;
				background: $BrandBlue;
				border-color: $BrandBlue;
			}
		}

		&.OnDarkBackground{

			&.NoHover:hover{
				background: transparent;
				border-color: white;
				color: white;
			}
		}
	}

	.NavigatorButton{
		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		@include Breakpoint(Large, ExtraLarge){

			&.ShowTouchSurface{
				padding: 1rem / 16 * 7 1rem;
			}
		}

		&.NoHover:hover{
			border-color: $GreyL;
			background: transparent;
			color: $GreyDD;

			& span{
				border-color: transparent;
			}
		}

		&.ShowTouchSurface{
			border: 1px solid $Grey;
			white-space: nowrap;
		}

		&.LowPriority{
			&.NoHover:hover span{
				border-color: transparent;
			}
		}
	}

	.HelperButton{
		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		@include Breakpoint(Large, ExtraLarge){

			&.ShowTouchSurface{
				padding: 1rem / 16 * 7 1rem;
			}
		}

		&.NoHover:hover{
			color: $BrandBlueL;
			background: transparent;

			& span{
				border-color: transparent;
			}
		}

		&.ShowTouchSurface{
			border: 1px solid $Grey;
			white-space: nowrap;
		}

		&.LowPriority{
			&.NoHover:hover {
				color: $Grey;

				span{
					border-color: transparent;
				}
			}

			&:hover {
				color: $Grey;
			}
		}
	}

	.TouchButtonWrapper{
		display: flex;
		align-items: center;

		.Pointer{
			height: 2px;
			width: 1.5rem;
			margin: 0 1rem 0 0;
			background: $Grey;
		}

		.Details{
			margin-top: 0;
		}
	}

	.Details{
		margin-bottom: 0;
		margin-top: 10px;
	}

	.ButtonWrapper.DarkBackground{
		padding: 32px 24px;
		background: $GreyD;
		margin: 0;

		.State{
			color: $White;
		}
	}

	.IconButtonInfo{
		margin-bottom: 0;
	}

	.IconButtons{
		display: inline-flex !important;

		.Details{
			display: block;
			margin: 0 auto 0 auto;
			@include FontSize('SN');
		}
	}

	.IconButtonGroups{
		margin: 0 2rem 1rem 0;

		&:last-of-type{
			margin-right: 0;
		}
	}

	.IconButton{
		background: none;
		border: none;

		i{
			font-size: 20px;
		}

		&.Search{
			height: 40px;
			width: 40px;
			margin-right: 1rem;
			background: $BrandOrange;
			border-radius: 3px;

			&:hover:not(.NoHover),
			&.Hover{
				background: $BrandOrangeD;
			}

			.SearchIcon{
				color: $White;
			}
		}

		.HeartIcon{
			Color: $BrandOrange;

			&:hover:not(.NoHover),
			&.Hover{
				Color: $BrandOrangeD;
			}
		}

		.PencilIcon{
			Color: $BrandBlue;

			&:hover:not(.NoHover),
			&.Hover{
				Color: $BrandBlueD;
			}
		}
	}

	.ExampleLinks{
		color: $GreyDD;

		span:first-of-type{
			margin-bottom: 1rem;
		}

		.NonVisitedLink{
			color: $Blue !important;
		}

		.VisitedLink{
			color: $BrandBlue !important;
		}
	}

	.ButtonsGuidelines{
		margin: $SpaceL 0 0 0;
	}

	.ContentWrapper .SubItem .VisualBlock.DisplayBlock{
		display: block;
	}
</style>
